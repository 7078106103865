import BaseModel from "@/models/BaseModel.js";

export default class ProspectPriseContact extends BaseModel {
  static entity = "prospect_prise_contact";
  static resourceUrl = "/suivi_ppde/prospect_prise_contact/";

  static MOYEN_COMMUNICATION = [
    { text: "Appel", value: "APPEL" },
    { text: "E-mail", value: "E-MAIL" },
    { text: "Rencontre", value: "RENCONTRE" },
    { text: "Courrier", value: "COURRIER" },
  ];

  static OBJET = [
    { text: "Mise à jour statut", value: "MISE_A_JOUR" },
    { text: "Fiche d'information complétée ou mise à jour des informations personnelles", value: "INFO_COMPLETEE" },
    { text: "Proposition de formation", value: "PROPO_FORMATION" },
    { text: "Proposition d'offres d'emploi", value: "PROPO_EMPLOI" },
    { text: "Proposition d'entrée en EBE", value: "PROPO_ENTREE_EBE" },
    { text: "Information sur la tenue d'une réunion", value: "INFO_REUNION" },
    { text: "Proposition de participation à une activité collective", value: "PROPO_ACT_COLLECTIVE" },
    { text: "Autre, à préciser", value: "AUTRE" },
  ];

  static fields() {
    return {
      id: this.uid(),
      prospect_info: this.attr(),
      contact_date: this.attr(),
      objet: this.attr(""),
      moyen_communication: this.attr(),
      modifications_message: this.attr(""),
      commentaire: this.attr(""),
    };
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      createPriseContact(payload) {
        return this.post(this.model.resourceUrl, payload, { handlingErrors: false });
      },
      updatePriseContact(payload) {
        return this.put(`${this.model.resourceUrl}${payload.id}/`, payload, { handlingErrors: false });
      },
    },
  };
}
