import BaseModel from "@/models/BaseModel.js";
import { downloadFile } from "@/helpers/ApiRequest";
import { ALERT_ERROR, ALERT_WARNING, addAlertDetails } from "@/helpers/alerts";

export default class PilotageCle extends BaseModel {
  static entity = "pilotage_cle";
  static resourceUrl = "/pilotage_cle/pilotage_cle/";

  static STATUS_IN_PROGRESS = "IN_PROGRESS";
  static STATUS_VALIDATED_CLE = "VALIDATED_CLE";
  static STATUS_VALIDATED_ETCLD = "VALIDATED_ETCLD";

  static STATUSES = [
    { text: "À compléter par le CLE", value: PilotageCle.STATUS_IN_PROGRESS, color: "grey", icon: "mdi-pen" },
    { text: "Validé par le CLE", value: PilotageCle.STATUS_VALIDATED_CLE, color: "orange", icon: "mdi-check" },
    { text: "Validé par ETCLD", value: PilotageCle.STATUS_VALIDATED_ETCLD, color: "green", icon: "mdi-check-all" },
  ];

  getStatus() {
    const status = PilotageCle.STATUSES.find((s) => s.value === this.status);
    return status || { text: "Statut inconnu", color: "red" };
  }

  static fields() {
    return {
      id: this.uid(),
      nom: this.string(""),
      previsions_n_annees: this.attr(),
      status: this.attr(),
      strategie_exhaustivite: this.attr(),
      date_debut: this.attr(),
      etape_prevision: this.attr(),
      etape_realise: this.attr(),
      cle: this.attr(),
      created_datetime: this.attr(),
    };
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      export(pilotageCleId) {
        const url = `${process.env.VUE_APP_API_URL}${PilotageCle.resourceUrl}${pilotageCleId}/export/`;
        downloadFile(url);
      },
      async getByCleId(cleId) {
        const response = await this.load({ cle: cleId });
        return response.entities[PilotageCle.entity] || [];
      },
      async getPreviousPilotage(pilotageId, stepName) {
        if (!pilotageId) {
          return;
        }
        const params = ["etape_realise", "etape_prevision"].includes(stepName) ? { [stepName]: true } : {};
        const response = await this.get(`${this.model.resourceUrl}${pilotageId}/previous_pilotage/`, { params });
        return response.entities?.[PilotageCle.entity]?.[0];
      },
      async _getErrors(pilotageId, stepName = null, dryRun = true) {
        try {
          const data = dryRun ? { dry_run: dryRun } : {};
          if (stepName) {
            data.step = stepName;
          }
          await this.post(`${this.model.resourceUrl}${pilotageId}/validate_pilotage_cle/`, data, { handlingErrors: false });
        } catch (error) {
          const errors = error.response?.data?.validation_errors || [];
          return addAlertDetails(errors, ALERT_ERROR);
        }
        return [];
      },
      async _getWarnings(pilotageId, stepName = null) {
        const results = await this.get(`${this.model.resourceUrl}${pilotageId}/alerts/?dryRun=true${stepName ? `&step=${stepName}` : ""}`);
        const warnings = results?.response?.data || [];
        return addAlertDetails(warnings, ALERT_WARNING);
      },
      async getAlerts(pilotageId, stepName = null) {
        const errors = await PilotageCle.api()._getErrors(pilotageId, stepName, true);
        const warnings = await PilotageCle.api()._getWarnings(pilotageId, stepName);
        return [...errors, ...warnings];
      },
      /*Alias function for better naming outside, it calls the validate with no dry run and return the errors if any*/
      async validatePilotage(pilotageId) {
        return await PilotageCle.api()._getErrors(pilotageId, null, false);
      },
      bulkAddStatus(pilotages, status) {
        const pilotagesData = pilotages.map((pilotage) => ({ id: pilotage.id, status: status }));
        return this.put(`${PilotageCle.resourceUrl}`, pilotagesData);
      },
      loadAvailableDates() {
        return this.get(`${PilotageCle.resourceUrl}available_dates/`);
      },
    },
  };
}
