import BaseModel from "@/models/BaseModel";

export default class BilanTerritoire extends BaseModel {
  static entity = "bilan_territoire";
  static resourceUrl = "/bilan/bilan_territoire/";

  static STATUS_ACTIVE = "ACTIVE";
  static STATUS_VALIDE = "VALIDE";

  static STATUSES = [
    { text: "À compléter par le CLE", value: BilanTerritoire.STATUS_ACTIVE, color: "grey", textColor: "white", icon: "mdi-pen" },
    { text: "Validé", value: BilanTerritoire.STATUS_VALIDE, color: "green", textColor: "white", icon: "mdi-check" },
  ];

  static fields() {
    return {
      id: this.uid(),
      cle: this.attr(),
      question_resume_experimentation: this.attr(),
      question_origine_demarche_tzcld: this.attr(),
      question_caracteristiques_territoire: this.attr(),
      question_territoire_clefs_reussite: this.attr(),
      question_impacts_creation_ebe: this.attr(),
      question_bilan_experimentation: this.attr(),
      question_apports_troisieme_loi: this.attr(),
      additional_files: this.attr(),
      status_log: this.attr(),
    };
  }

  get currentStatus() {
    const status = BilanTerritoire.STATUSES.find((status) => status.value === this.status_log[0].value);
    return status ?? { text: "Statut inconnu", color: "red", icon: "mdi-x" };
  }

  validate() {
    return BilanTerritoire.api().validate(this.id);
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      validate(id) {
        return this.post(`${this.model.resourceUrl}${id}/validate/`, {});
      },
      bulkAddStatus(bilans, status) {
        const data = {
          status,
          bilan_ids: bilans.map(({ id }) => id),
        };
        return this.post(`${this.model.resourceUrl}update_status/`, data);
      },
    },
  };
}
