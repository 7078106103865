import BaseModel from "@/models/BaseModel";
import EbeActiviteCategorieClassification from "@/models/notrexp/EbeActiviteCategorieClassification";

export default class EbeActiviteCategorie extends BaseModel {
  static entity = "ebe_activite_categorie";
  static resourceUrl = "/notrexp/ebe_activite_categorie/";

  static fields() {
    return {
      id: this.uid(),
      titre: this.string(""),
      categorie: this.attr(),
      classification: this.attr(),
    };
  }

  isMain() {
    return !this.categorie;
  }

  getParent() {
    return EbeActiviteCategorie.query().whereId(this.categorie).first();
  }

  getChildren() {
    return EbeActiviteCategorie.query().where("categorie", this.id).all();
  }

  getClassification() {
    return EbeActiviteCategorieClassification.query().whereId(this.classification).first();
  }

  static getCategorieName(activite) {
    return activite?.categorie ? activite?.titre : "";
  }
  static getParentCategoryName(activite) {
    return activite?.categorie ? EbeActiviteCategorie.find(activite.categorie)?.titre : activite?.titre;
  }

  static getActivityCategorieFullName(activity) {
    const activite = EbeActiviteCategorie.find(activity.activite);
    const parentCategoryName = EbeActiviteCategorie.getParentCategoryName(activite);
    const subCategoryName = EbeActiviteCategorie.getCategorieName(activite);
    let fullCategoryName = parentCategoryName;
    if (subCategoryName) {
      fullCategoryName += ` - ${subCategoryName}`;
    }
    return `${fullCategoryName}${activity.intitule ? " - " + activity.intitule : ""}`;
  }

  static sortByFullName(a, b) {
    const aTitle = EbeActiviteCategorie.getActivityCategorieFullName(a);
    const bTitle = EbeActiviteCategorie.getActivityCategorieFullName(b);
    if (aTitle < bTitle) {
      return -1;
    }
    if (aTitle > bTitle) {
      return 1;
    }
    return 0;
  }
}
