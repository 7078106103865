import axios from "axios";
import Modal from "@/helpers/Modal.js";
import store from "@/stores";

const request = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
});

export class AxiosAbortError extends Error {
  constructor(...params) {
    super(...params);
    Error.captureStackTrace?.(this, AxiosAbortError);
    this.name = "AxiosAbortError";
  }
}

const API_UNREACHABLE_ERROR_TITLE = "Notre application n'est pas disponible pour le moment.";
const API_UNREACHABLE_ERROR_DESCRIPTION = "Veuillez réessayer un peu plus tard ou rafraichir la page";

request.interceptors.response.use(
  (response) => {
    if (store.state?.app?.isAPIUnreachable) {
      store?.commit("app/SET_IS_API_UNREACHABLE", false, { root: true });
    }
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject(new AxiosAbortError(error));
    }

    const isAPIUnreachable = !error.response;
    if (isAPIUnreachable) {
      if (store.state?.app?.isAPIUnreachable === false) {
        store.commit("app/SET_IS_API_UNREACHABLE", true, { root: true });
      }

      if (error.config?.handlingAPIUnreachable !== false) {
        Modal.apiErrors(API_UNREACHABLE_ERROR_TITLE, [API_UNREACHABLE_ERROR_DESCRIPTION]);
      }
    }

    if (error.response.config?.handlingErrors !== false) {
      handleErrors(error);
    }

    return Promise.reject(error);
  }
);

async function handleErrors(error) {
  let errors = [];
  let data = error.response?.data;

  // Handle Blob response type for file downloading.
  if (data instanceof Blob && data.type === "application/json") {
    data = JSON.parse(await data.text());
  }

  if (!error.response) {
    errors.push({ title: API_UNREACHABLE_ERROR_TITLE, message: API_UNREACHABLE_ERROR_DESCRIPTION });
  } else if (error.response.status == 400 && data.validation_errors) {
    errors.push({
      title: "Erreur de validation",
      message: flattenValidationErrors(data.validation_errors),
    });
  } else if (error.response.status === 404) {
    errors.push({ title: "404 - Ressource non trouvée", message: data?.detail || "Cette ressource n'existe pas." });
  } else if (error.response.status === 403) {
    errors.push({
      title: "403 - Privilèges insuffisants",
      message: data?.detail || "Vous n'avez pas la permission d'effectuer cette action.",
    });
  } else if (error.response.status > 400 && error.response.status < 500) {
    errors.push({ title: "Erreur du serveur", message: "Si le probleme persiste, merci de contacter un administrateur." });
  } else if (error.response.status === 500 && data?.detail && data?.detail != "Unknown server error") {
    errors.push({ title: "Erreur du serveur", message: data.detail });
  } else if (error.response.status === 500) {
    errors.push({ title: "Erreur du serveur", message: "Si le probleme persiste, merci de contacter un administrateur." });
  } else {
    errors.push({ title: "Erreur inconnue", message: "Si le probleme persiste, merci de contacter un administrateur." });
  }

  errors.forEach(({ title, message }) => {
    Modal.apiErrors(title, Array.isArray(message) ? message : [message]);
  });

  return errors;
}

function flattenValidationErrors(validationErrors) {
  let errors = [];

  if (typeof validationErrors === "string") {
    errors.push(validationErrors);
  } else if (Array.isArray(validationErrors)) {
    for (let validationError of validationErrors) {
      errors = errors.concat(flattenValidationErrors(validationError));
    }
  } else {
    for (let key in validationErrors) {
      errors = errors.concat(flattenValidationErrors(validationErrors[key]));
    }
  }

  return errors;
}

async function downloadFile(url) {
  const response = await request({
    url: url,
    method: "GET",
    responseType: "blob",
  });

  let filename = undefined;
  const contentDisposition = response.headers["content-disposition"];
  const filenameMatch = contentDisposition.match(/filename="(.*)"/);
  if (filenameMatch.length > 0) {
    filename = filenameMatch[1];
  }

  const href = URL.createObjectURL(response.data);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export { flattenValidationErrors, handleErrors, downloadFile };
export default request;
