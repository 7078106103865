import { Model } from "@vuex-orm/core";
import Region from "@/models/Region";
import Departement from "@/models/Departement";
import Commune from "@/models/Commune";
import Epci from "@/models/Epci";
import TypeFinanceur from "@/models/TypeFinanceur";

export default class Financeur extends Model {
  static entity = "financeur";

  static fields() {
    return {
      id: this.uid(),
      type_financeur: this.attr(),
      financeur_region: this.attr(),
      financeur_departement: this.attr(),
      financeur_commune: this.attr(),
      financeur_epci: this.attr(),
      financeur_texte: this.attr(),
    };
  }

  static getName(financeur) {
    const type_financeur = TypeFinanceur.find(financeur.type_financeur);
    if (!type_financeur) {
      return "";
    }

    if (financeur.financeur_region) {
      const region = Region.find(financeur.financeur_region);
      return `${type_financeur.nom} - ${region.nom}`;
    }

    if (financeur.financeur_departement) {
      const departement = Departement.find(financeur.financeur_departement);
      return `${type_financeur.nom} - ${departement.nom}`;
    }

    if (financeur.financeur_commune) {
      const commune = Commune.find(financeur.financeur_commune);
      return `${type_financeur.nom} - ${commune.nom}`;
    }

    if (financeur.financeur_epci) {
      const epci = Epci.find(financeur.financeur_epci);
      return `${type_financeur.nom} - ${epci.nom}`;
    }

    if (financeur.financeur_texte) {
      return `${type_financeur.nom} - ${financeur.financeur_texte}`;
    }

    return type_financeur.nom;
  }

  static async getOrLoad(id) {
    let financeur = this.find(id);
    if (financeur) return financeur;

    let response = null;
    try {
      response = await this.api().get(`/financeur/${id}/`);
      return response.entities.financeur[0];
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  static async loadRelated(financeur) {
    if (financeur.financeur_region) {
      await Region.getOrLoad(financeur.financeur_region);
    }

    if (financeur.financeur_departement) {
      await Departement.getOrLoad(financeur.financeur_departement);
    }

    if (financeur.financeur_commune) {
      await Commune.getOrLoad(financeur.financeur_commune);
    }

    if (financeur.financeur_epci) {
      await Epci.getOrLoad(financeur.financeur_epci);
    }
  }
}
