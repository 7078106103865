import { Model } from "@vuex-orm/core";

export default class Commune extends Model {
  static entity = "commune";

  static fields() {
    return {
      id: this.attr(),
      nom: this.attr(),
      departement: this.attr(),
      code_insee: this.attr(),
      epci: this.attr(),
    };
  }

  static async getOrLoad(id, name) {
    if (!id && !name) return null;
    if (id) {
      let commune = this.query().where("id", id).first();
      if (commune) return commune;
    }

    let url = "";
    if (id) url = `/communes/${id}/`;
    if (name) url = `/communes/?nom=${name}`;

    let response = null;
    try {
      response = await this.api().get(url);
      return response.entities.commune[0];
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  static apiConfig = {
    actions: {
      search(value, offset) {
        return this.get("communes/", {
          params: {
            search: value,
            limit: 50,
            offset: offset,
          },
        });
      },
    },
  };
}
