import BaseModel from "@/models/BaseModel.js";
import Individu from "@/models/notrexp/Individu";

export default class ProspectStatus extends BaseModel {
  static entity = "prospect_status";
  static resourceUrl = "/suivi_ppde/prospect_status/";

  static A_COMPLETER = "A_COMPLETER";
  static A_VALIDER = "A_VALIDER";
  static VOLONTAIRE_ELIGIBLE = "VOLONTAIRE_ELIGIBLE";
  static VOLONTAIRE = "VOLONTAIRE";
  static EN_EBE = "EN_EBE";
  static EN_EBE_VIA_DSN = "EN_EBE_VIA_DSN";
  static HORS_EBE_VIA_DSN = "HORS_EBE_VIA_DSN";
  static CDI_HORS_EBE = "CDI_HORS_EBE";
  static CDD_PLUS_DE_6_MOIS_HORS_EBE = "CDD_PLUS_DE_6_MOIS_HORS_EBE";
  static CDD_MOINS_DE_6_MOIS_HORS_EBE = "CDD_MOINS_DE_6_MOIS_HORS_EBE";
  static FORMATION_OU_AUTRE_DISPOSITIF = "FORMATION_OU_AUTRE_DISPOSITIF";
  static EN_FORMATION_PREALABLE_EBE = "EN_FORMATION_PREALABLE_EBE";
  static EN_SIAE = "EN_SIAE";
  static EN_DISPOSITIF_INSERTION_JEUNES = "EN_DISPOSITIF_INSERTION_JEUNES";
  static CREATION_ACTIVITE = "CREATION_ACTIVITE";
  static SANS_NOUVELLES = "SANS_NOUVELLES";
  static PLUS_VOLONTAIRE = "PLUS_VOLONTAIRE";
  static PLUS_ELIGIBLE = "PLUS_ELIGIBLE";

  static STATUSES = [
    {
      text: "À compléter",
      value: ProspectStatus.A_COMPLETER,
      color: "hsl(23, 91%, 59%, 1)",
      textColor: "white",
      manual: false,
      needEligibilite: false,
    },
    {
      text: "À valider",
      value: ProspectStatus.A_VALIDER,
      color: "hsl(349, 77%, 57%, 1)",
      textColor: "white",
      manual: false,
      needEligibilite: false,
    },
    {
      text: "Volontaire éligible",
      value: ProspectStatus.VOLONTAIRE_ELIGIBLE,
      color: "hsl(220, 96%, 90%, 1)",
      manual: false,
      needEligibilite: false,
    },
    { text: "Volontaire", value: ProspectStatus.VOLONTAIRE, color: "hsl(220, 96%, 90%, 1)", manual: true, needEligibilite: true },
    { text: "En EBE", value: ProspectStatus.EN_EBE, color: "hsl(196, 92%, 90%, 1)", manual: true, needEligibilite: true },
    {
      text: "En EBE via DSN",
      value: ProspectStatus.EN_EBE_VIA_DSN,
      color: "hsl(196, 92%, 90%, 1)",
      manual: false,
      needEligibilite: true,
    },
    {
      text: "Hors EBE via DSN",
      value: ProspectStatus.HORS_EBE_VIA_DSN,
      color: "hsl(196, 92%, 90%, 1)",
      manual: false,
      needEligibilite: true,
    },
    { text: "En CDI hors EBE", value: ProspectStatus.CDI_HORS_EBE, color: "hsl(165, 72%, 86%, 1)", manual: true, needEligibilite: true },
    {
      text: "En CDD de plus de 6 mois hors EBE",
      shortText: "CDD +6 mois hors EBE",
      value: ProspectStatus.CDD_PLUS_DE_6_MOIS_HORS_EBE,
      color: "hsl(315, 100%, 93%, 1)",
      manual: true,
      needEligibilite: true,
    },
    {
      text: "En CDD de moins de 6 mois hors EBE",
      shortText: "CDD -6 mois hors EBE",
      value: ProspectStatus.CDD_MOINS_DE_6_MOIS_HORS_EBE,
      color: "hsl(345, 100%, 93%, 1)",
      manual: true,
      needEligibilite: true,
    },
    {
      text: "En formation ou autre dispositif d'accès à l'emploi",
      shortText: "En formation",
      value: ProspectStatus.FORMATION_OU_AUTRE_DISPOSITIF,
      color: "hsl(19, 95%, 92%, 1)",
      manual: true,
      needEligibilite: true,
    },
    {
      text: "En formation préalable à l'entrée en EBE",
      shortText: "En formation pré-EBE",
      value: ProspectStatus.EN_FORMATION_PREALABLE_EBE,
      color: "hsl(19, 95%, 92%, 1)",
      manual: true,
      needEligibilite: true,
    },
    {
      text: "En SIAE",
      value: ProspectStatus.EN_SIAE,
      color: "hsl(19, 95%, 92%, 1)",
      manual: true,
      needEligibilite: true,
    },
    {
      text: "En dispositif d'insertion jeunes",
      shortText: "Insertion jeunes",
      value: ProspectStatus.EN_DISPOSITIF_INSERTION_JEUNES,
      color: "hsl(19, 95%, 92%, 1)",
      manual: true,
      needEligibilite: true,
    },
    { text: "Création d'activité", value: ProspectStatus.CREATION_ACTIVITE, color: "hsl(264, 93%, 94%, 1)", manual: true, needEligibilite: true },
    {
      text: "Sans nouvelles depuis plus de 6 mois",
      shortText: "Sans nouvelle",
      value: ProspectStatus.SANS_NOUVELLES,
      color: "hsl(0, 0%, 93%, 1)",
      manual: false,
      needEligibilite: true,
    },
    { text: "Plus volontaire", value: ProspectStatus.PLUS_VOLONTAIRE, color: "hsl(43, 100%, 86%, 1)", manual: true, needEligibilite: true },
    {
      text: "Plus éligible (déménagement, droit à la retraite, décès)",
      shortText: "Plus éligible",
      value: ProspectStatus.PLUS_ELIGIBLE,
      color: "hsl(105, 76%, 87%, 1)",
      manual: true,
      needEligibilite: true,
    },
  ];

  static CREATION_TYPE_MANUEL = "MANUEL";
  static CREATION_TYPE_AUTO_VIA_FRONT = "AUTO_VIA_FRONT";
  static CREATION_TYPE_AUTO_VIA_ELIGIBILITE = "AUTO_VIA_ELIGIBILITE";
  static CREATION_TYPE_AUTO_VIA_JOB = "AUTO_VIA_JOB";
  static CREATION_TYPE_AUTO_VIA_DSN = "AUTO_VIA_DSN";

  static CREATION_TYPES = [
    { text: "Manuel", value: ProspectStatus.CREATION_TYPE_MANUEL },
    { text: "Depuis une action sur l'interface", value: ProspectStatus.CREATION_TYPE_AUTO_VIA_FRONT },
    { text: "Depuis la décision d'élégibilité", value: ProspectStatus.CREATION_TYPE_AUTO_VIA_ELIGIBILITE },
    { text: "Depuis une tâche automatisée", value: ProspectStatus.CREATION_TYPE_AUTO_VIA_JOB },
    { text: "Depuis l'import d'une DSN", value: ProspectStatus.CREATION_TYPE_AUTO_VIA_DSN },
  ];

  static INVALID_REASON_EN_EBE = "EN_EBE";
  static INVALID_REASON_CONTRAT_AVANT_ELIGIBILITE = "CONTRAT_AVANT_ELIGIBILITE";
  static INVALID_REASONS = [
    { value: this.INVALID_REASON_EN_EBE, text: "Le volontaire a été détecté en EBE à cette date." },
    { value: this.INVALID_REASON_CONTRAT_AVANT_ELIGIBILITE, text: "La date d'éligibilité est postérieure à une date de début de contrat." },
  ];

  static MAX_ERRORS_PER_VOLUNTEER = 1;

  static fields() {
    return {
      id: this.uid(),
      prospect_info_id: this.attr(null),
      status_type: this.attr(),
      status_datetime: this.attr(),
      creation_type: this.attr(),
      replaced_by: this.attr(),
      invalid_reason: this.attr(),
      created_by: this.attr(),
      created_datetime: this.attr(),
      human_order_on_status_change: this.attr(),
      total_volontaires_on_status_change: this.attr(),
    };
  }

  static getManualStatuses() {
    return ProspectStatus.STATUSES.filter((s) => s.manual);
  }

  static availableStatus(volunteers) {
    if (!volunteers?.length) {
      return [];
    }
    const firstVolunteerEligibilite = volunteers[0].prospectinfoeligibilite?.eligibilite;
    const volunteersHaveDifferentEligibilite = volunteers.some((v) => v.prospectinfoeligibilite?.eligibilite !== firstVolunteerEligibilite);
    if (volunteersHaveDifferentEligibilite) {
      return [];
    }

    return ProspectStatus.getManualStatuses().filter((status) => status.needEligibilite === firstVolunteerEligibilite);
  }

  static getStatus(status) {
    return {
      ...status,
      ...(ProspectStatus.STATUSES.find((s) => s.value === status.status_type) ?? {}),
    };
  }
  static getCreationType(creationType) {
    return {
      ...(ProspectStatus.CREATION_TYPES.find((c) => c.value === creationType) ?? {}),
    };
  }

  get text() {
    return ProspectStatus.getStatus(this).text;
  }
  get shortText() {
    return ProspectStatus.getStatus(this).shortText;
  }
  get color() {
    return ProspectStatus.getStatus(this).color;
  }
  get textColor() {
    return ProspectStatus.getStatus(this).textColor;
  }
  get isCreationManual() {
    return this.creation_type === ProspectStatus.CREATION_TYPE_MANUEL;
  }
  get creationTypeText() {
    return ProspectStatus.getCreationType(this.creation_type)?.text;
  }
  get invalidReason() {
    return ProspectStatus.INVALID_REASONS.find((r) => r.value === this.invalid_reason)?.text ?? null;
  }
  get needReplacement() {
    return this.status_type === ProspectStatus.HORS_EBE_VIA_DSN;
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async createStatus(volunteers, type, date, dryRun) {
        if (!volunteers || volunteers.length === 0) {
          return {};
        }

        let isBulk = false;
        if (volunteers?.length > 1) {
          isBulk = true;
        }

        let errors = null;

        try {
          let payload = isBulk
            ? volunteers?.map(({ id }) => ({
                prospect_info: id,
                status_type: type,
                status_date: date,
              }))
            : {
                prospect_info: volunteers[0].id,
                status_type: type,
                status_date: date,
              };

          await ProspectStatus.api().post(`${ProspectStatus.resourceUrl}${dryRun ? "?dry_run" : ""}`, payload, {
            handlingErrors: false,
            save: !dryRun,
          });
        } catch (e) {
          if (e.response?.status === 400 && e.response?.data?.validation_errors) {
            // validation_errors is an object of type
            // { [prospectInfoId]: ErrorMessages[] }
            if (isBulk) {
              errors = Object.entries(e.response?.data?.validation_errors)
                ?.map(([prospectInfoId, errors]) => {
                  const individu = volunteers?.find(({ id }) => id === prospectInfoId)?.individu;
                  const name = Individu.getFullName(individu);
                  return errors?.slice(0, ProspectStatus.MAX_ERRORS_PER_VOLUNTEER).map((error) => `${name} - ${error}`);
                })
                .flat();
            } else {
              // validation_errors is an array of error messages
              errors = e.response?.data?.validation_errors?.slice(0, ProspectStatus.MAX_ERRORS_PER_VOLUNTEER);
            }
          } else {
            console.error(e);
            return { genericError: e };
          }
        }

        return errors ? { errors } : {};
      },
      async updateStatus(statusId, prospectInfoId, status_type, status_date, dry_run = false) {
        if (!statusId || !prospectInfoId) return {};

        let errors = null;
        try {
          await ProspectStatus.api().put(
            `${ProspectStatus.resourceUrl}${statusId}/${dry_run ? "?dry_run" : ""}`,
            {
              prospect_info: prospectInfoId,
              status_type,
              status_date,
            },
            { handlingErrors: false, save: !dry_run }
          );
        } catch (e) {
          if (e.response?.status === 400 && e.response?.data?.validation_errors) {
            // validation_errors is an array of error messages
            errors = e.response?.data?.validation_errors?.slice(0, ProspectStatus.MAX_ERRORS_PER_VOLUNTEER);
          } else {
            console.error(e);
            return { genericError: e };
          }
        }

        return errors ? { errors } : {};
      },
    },
  };
}
