import BaseModel from "@/models/BaseModel.js";

export default class ProspectProfilProfessionnel extends BaseModel {
  static entity = "prospect_profil_professionnel";
  static resourceUrl = "/suivi_ppde/prospect_profil_professionnel/";

  static NIVEAUX_DIPLOME = [
    { text: "Aucun diplôme ou CEP", value: "SANS_DIPLOME" },
    { text: "BEPC, Brevet, DNB", value: "BREVET" },
    { text: "CAP, BEP", value: "CAP" },
    { text: "BAC", value: "BAC" },
    { text: "BAC+2", value: "BAC_2" },
    { text: "BAC+3 ou BAC+4", value: "BAC_3_4" },
    { text: "BAC+5 ou plus", value: "BAC_5_PLUS" },
  ];

  static NIVEAUX_FORMATION = [
    { text: "Formation non certifiante", value: "NON_CERTIFIANTE" },
    { text: "Brevet professionnel", value: "BREVET_PRO" },
    { text: "CAP, BEP", value: "CAP" },
    { text: "BAC", value: "BAC" },
    { text: "BAC+2", value: "BAC_2" },
    { text: "BAC+3 ou BAC+4", value: "BAC_3_4" },
    { text: "BAC+5 ou plus", value: "BAC_5_PLUS" },
    { text: "Certification non obtenue", value: "CERTIFICATION_NON_OBTENUE" },
    { text: "Autres ? À préciser", value: "AUTRE" },
  ];

  static EXPERIENCE_PROFESSIONNELLE_DUREE_UNITE = [
    { text: "année(s)", value: "YEARS", unit: 365 },
    { text: "mois(s)", value: "MONTHS", unit: 30 },
    { text: "jour(s)", value: "DAYS", unit: 1 },
  ];

  static ORIENTATIONS = [
    { text: "Orientation EBE désirée", value: "EBE" },
    { text: "Orientation hors EBE", value: "HORS_EBE" },
    { text: "Orientation mixte", value: "MIXTE" },
  ];

  static fields() {
    return {
      id: this.attr(null),
      prospect_id: this.attr(null),

      skills_txt: this.attr(),
      diplome: this.attr(),
      formations: this.attr([]),
      habilitations: this.attr([]),
      experiences_pro: this.attr(),
      savoir_faire: this.attr(),
      permis_conduite: this.attr(),

      working_wishes: this.attr(),
      temps_travail_souhait: this.attr(),
      souhaits_activite: this.attr(),
      projet_pro_long_terme: this.attr(),
      dispo: this.attr(),
      date_dispo: this.attr(),
      adaptation_temps: this.attr(),
      adaptation_poste: this.attr(),
      contraintes_mobilite: this.attr(),
      orientation: this.attr(),
      orientation_commentaire: this.attr(),

      learning_wishes: this.attr(),
      besoin_formation: this.attr(),
    };
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
    },
  };

  static getNiveauLabel(niveau) {
    return ProspectProfilProfessionnel.NIVEAUX_FORMATION.find((s) => s.value === niveau)?.text;
  }

  static getDureeUnite(unite) {
    return ProspectProfilProfessionnel.EXPERIENCE_PROFESSIONNELLE_DUREE_UNITE.find((s) => s.value === unite);
  }
}
