import BaseModel from "@/models/BaseModel";

export default class SuiviTerritoirePriseContactTheme extends BaseModel {
  static entity = "suivi_territoire_prise_contact_theme";
  static resourceUrl = "/suivi_territoire/prise_contact_theme/";

  static fields() {
    return {
      id: this.uid(),
      titre: this.attr(),
    };
  }
}
