import BaseModel from "@/models/BaseModel";

export default class EbeActiviteCategorieClassification extends BaseModel {
  static entity = "ebe_activite_categorie_classification";
  static resourceUrl = "/notrexp/ebe_activite_categorie_classification/";

  static fields() {
    return {
      id: this.uid(),
      titre: this.attr(),
    };
  }

  static getTitre(id) {
    return EbeActiviteCategorieClassification.query().whereId(id).first()?.titre;
  }
}
