import BaseModel from "@/models/BaseModel";

export default class QuestionQualitative extends BaseModel {
  static entity = "question_qualitative_ebe_reponse";
  static resourceUrl = "/pilotage/question_qualitative_ebe_reponse/";

  static fields() {
    return {
      id: this.uid(),
      rdv_pilotage: this.attr(),
      question: this.attr(),
      reponse_ebe: this.attr(""),
      reponse_etcld: this.attr(""),
    };
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
    },
  };
}
