<template>
  <div class="page">
    <v-card class="mb-0 px-4" style="height: 100%" :loading="loading">
      <iframe
        v-if="iframeUrl"
        v-show="!loading"
        id="metabase_iframe"
        :src="iframeUrl"
        frameborder="0"
        allowtransparency
        @load="onIframeLoaded"
      ></iframe>
      <div v-show="loading" class="py-4 text-center text-small text--disabled">Chargement de Metabase...</div>
    </v-card>
  </div>
</template>

<script>
import ApiRequest from "@/helpers/ApiRequest.js";

export default {
  name: "DisplayMetabase",
  props: {
    metabaseModule: {
      type: String,
      default: "dashboard_integration",
    },
    metabaseView: String,
    params: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      abortController: null,
      loading: true,
      iframeUrl: "",
      timeoutId: null,
    };
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  methods: {
    async loadMetabase() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      this.abortController?.abort();
      this.abortController = new AbortController();
      this.loading = true;
      try {
        const response = await ApiRequest.get(`/metabase/${this.metabaseModule}/${this.metabaseView}/`, {
          params: this.params,
          handlingErrors: false,
          signal: this.abortController.signal,
        });
        this.iframeUrl = response.data.iframe_url;

        const timeout = new Date(response.data.url_expiration) - new Date();
        this.timeoutId = setTimeout(() => this.loadMetabase(), timeout);
      } catch (error) {
        const isNotConfigured = error.response?.status == 500 && error.response?.data?.code == "METABASE_NOT_CONFIGURED";
        return this.$emit("metabase-error", isNotConfigured ? "METABASE_NOT_CONFIGURED" : "OTHER");
      }
    },
    onIframeLoaded() {
      this.loading = false;
    },
  },
  watch: {
    params: {
      immediate: true,
      handler: "loadMetabase",
    },
    metabaseView: {
      handler: "loadMetabase",
    },
  },
};
</script>

<style lang="scss" scoped>
#metabase_iframe {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
