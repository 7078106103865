import AttachedS3File from "@/models/AttachedS3File";
import BaseModel from "@/models/BaseModel";

export default class BilanGens extends BaseModel {
  static entity = "bilan_gens";
  static resourceUrl = "/bilan/bilan_gens/";

  static STATUS = Object.freeze({
    ACTIVE: "ACTIVE",
    VALIDE: "VALIDE",
  });

  static STATUSES = Object.freeze([
    {
      text: "À compléter",
      value: BilanGens.STATUS.ACTIVE,
      color: "grey",
      textColor: "white",
      icon: "mdi-pen",
    },
    {
      text: "Validé",
      value: BilanGens.STATUS.VALIDE,
      color: "green",
      textColor: "white",
      icon: "mdi-check",
    },
  ]);

  static CONTENT_FORMAT = Object.freeze({
    TEXTE: "TEXTE",
    AUDIO: "AUDIO",
    VIDEO: "VIDEO",
    AUTRE: "AUTRE",
  });

  static CONTENT_FORMATS = Object.freeze([
    {
      text: "Texte",
      value: BilanGens.CONTENT_FORMAT.TEXTE,
    },
    {
      text: "Audio",
      value: BilanGens.CONTENT_FORMAT.AUDIO,
      icon: "mdi-volume-high",
      authorizedFileTypes: [AttachedS3File.AUDIO],
    },
    {
      text: "Vidéo",
      value: BilanGens.CONTENT_FORMAT.VIDEO,
      icon: "mdi-video",
      authorizedFileTypes: [AttachedS3File.VIDEO],
    },
    {
      text: "Autre",
      value: BilanGens.CONTENT_FORMAT.AUTRE,
      icon: "mdi-file-document",
      authorizedFileTypes: [AttachedS3File.IMAGE, AttachedS3File.PDF],
    },
  ]);

  static getContentFormat = (input) => {
    const contentFormat = BilanGens.CONTENT_FORMATS.find((format) => format.value === input);
    return contentFormat ?? { text: "Format inconnu" };
  };

  static S3_TARGET_QUESTION_CONTENT_FILE = "bilan.bilan_gens.question_content_file";
  static S3_TARGET_QUESTION_ILLUSTRATION = "bilan.bilan_gens.question_illustration";
  static S3_TARGET_ACCORDS_CAPTATION = "bilan.bilan_gens.accords_captation";

  static fields() {
    return {
      id: this.uid(),
      status_log: this.attr(),
      created_etcld: this.attr(),
      created_cle: this.attr(),
      created_ebe: this.attr(),
      temoins: this.attr([]),
      question_titre: this.attr(),
      question_date: this.attr(),
      question_format: this.attr(),
      question_content_texte: this.attr(),
      question_content_file: this.attr(),
      question_illustration: this.attr(),
      accords_captation: this.attr(),
      accord_charte: this.attr(),
      accord_rgpd: this.attr(),
      question_info_supplementaire: this.attr(),

      // for list serializer, will have the first name
      question_temoin_nom: this.attr(),
    };
  }

  get currentStatus() {
    if (!this.status_log?.length) return { text: "Statut inconnu", color: "red", icon: "mdi-close" };
    const status = BilanGens.STATUSES.find((status) => status.value === this.status_log[0].value);
    return status ?? { text: "Statut inconnu", color: "red", icon: "mdi-close" };
  }

  get addedDate() {
    if (!this.status_log?.length) return null;
    return this.status_log.at(-1).datetime.split("T")[0];
  }

  get modifiedDate() {
    if (!this.status_log?.length) return null;
    return this.status_log[0].datetime.split("T")[0];
  }

  get fullTitle() {
    return `${this.question_temoin_nom ?? "Nom non renseigné"} – ${this.question_titre ?? "Titre non renseigné"}`;
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      validate(id) {
        return this.post(`${this.model.resourceUrl}${id}/validate/`, {});
      },
      bulkAddStatus(bilans, status) {
        const data = {
          status,
          bilan_ids: bilans.map(({ id }) => id),
        };
        return this.post(`${this.model.resourceUrl}update_status/`, data);
      },
    },
  };
}
