import { Model } from "@vuex-orm/core";

export default class EbePrevisionCoutInvestissement extends Model {
  static entity = "ebe_prevision_cout_investissement";

  static fields() {
    return {
      id: this.uid(),
      plan_investissement: this.attr(),
      montant: this.attr(),
      annee: this.attr(),
    };
  }

  static apiConfig = {
    actions: {
      load(params) {
        return this.get("/pilotage/ebe_prevision_cout_investissement/", {
          params: params,
        });
      },
      save(instance) {
        if (instance.id.startsWith("$")) {
          return this.post("/pilotage/ebe_prevision_cout_investissement/", instance);
        } else {
          return this.put(`/pilotage/ebe_prevision_cout_investissement/${instance.id}/`, instance);
        }
      },
    },
  };
}
