import BaseModel from "@/models/BaseModel.js";

export default class Individu extends BaseModel {
  static entity = "individu";
  static resourceUrl = "/notrexp/individu/";

  static GENDER_ITEMS = [
    { text: "Femme", value: "FEMME" },
    { text: "Homme", value: "HOMME" },
    { text: "Non communiqué", value: "NON_COMMUNIQUE" },
  ];

  static fields() {
    return {
      id: this.attr(null),
      nom: this.attr(),
      nom_usage: this.attr(),
      prenoms: this.attr(),
      date_naissance: this.attr(""),
      genre: this.attr(""),
      nir: this.attr(),
      created_datetime: this.attr(),
      modified_datetime: this.attr(),
      created_by: this.attr(),
      unique_identifier_isset: this.attr(),
      prospect_info_id: this.attr(null),
    };
  }

  static getFullName(individu) {
    const { nom_usage, nom, prenoms } = individu;

    const nomUsage = nom_usage ? ` (${nom_usage}) ` : " ";
    return `${nom}${nomUsage}${prenoms}`;
  }

  get fullName() {
    return Individu.getFullName(this);
  }
}
