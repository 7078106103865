import BaseModel from "@/models/BaseModel.js";

export default class PermisConduite extends BaseModel {
  static entity = "permis_conduite";
  static resourceUrl = "/suivi_ppde/permis_conduite/";

  static fields() {
    return {
      id: this.uid(),
      titre: this.attr(),
    };
  }
}
