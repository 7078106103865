import BaseModel from "@/models/BaseModel.js";

export default class FECActiviteRealisee extends BaseModel {
  static entity = "fec_activite_realisee";
  static resourceUrl = "/fec/fec_activite_realisee/";

  static fields() {
    return {
      id: this.uid(),
      fec_import: this.attr(),
      activite: this.attr(),
      intitule: this.attr(),
      ca: this.attr(),
      subvention_exploitation: this.attr(),
      achat_consomme: this.attr(),
      nbr_moyen_etp_contractuel: this.attr(),
      nbr_moyen_etp_travailles: this.attr(),
      nbr_moyen_etp_travailles_original: this.attr(),
    };
  }
}
