import { Model } from "@vuex-orm/core";

export default class EbePlanTresorerie extends Model {
  static entity = "ebe_plan_tresorerie";

  static fields() {
    return {
      id: this.uid(),
      type_tresorerie: this.attr(),
      encaissement: this.attr(),
      exploitation: this.attr(),
      display_order: this.attr(),
    };
  }

  static state() {
    return {
      allLoaded: false,
    };
  }

  static apiConfig = {
    actions: {
      loadAll() {
        let state = this.model.store().state.entities[this.model.entity];
        if (state.allLoaded) {
          return Promise.resolve();
        }
        return this.get("/pilotage/ebe_plan_tresorerie/").then(() => {
          this.model.commit((state) => {
            state.allLoaded = true;
          });
        });
      },
    },
  };
}
