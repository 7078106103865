import DSNImport from "@/models/financement/DSNImport";
import EbePrevisionEtp from "@/models/financement/previsionEtp/EbePrevisionEtp";

export default class EbePrevisionEtpMois extends EbePrevisionEtp {
  static entity = "ebe_prevision_etp_mois";
  static resourceUrl = "/financement/ebe_prevision_etp_mois/";

  static DSN_STATUS_NONE = "NONE";

  static DSN_STATUSES = [
    {
      text: "En cours",
      value: DSNImport.STATUS_ACTIVE,
      description:
        "La DSN est actuellement en cours de téléversement, la valeur de l'ETP travaillé réalisé sera affichée dès que les données seront validées.",
      color: "orange",
      textColor: "white",
      icon: "mdi-pen",
    },
    {
      text: "Pas de DSN",
      value: EbePrevisionEtpMois.DSN_STATUS_NONE,
      description:
        "Il n'y a pas encore de DSN, la valeur de l'ETP travaillé réalisé sera affichée dès que les données seront téléversées et validées.",
      color: "grey lighten-3",
      icon: "mdi-close",
    },
  ];

  static fields() {
    return {
      id: this.uid(),
      ebe: this.attr(),
      cle: this.attr(),
      ebe_prevision_etp_annee: this.attr(),
      mois: this.attr(),
      etp_ppde: this.attr(),
      etp_nppde: this.attr(),
      auto_close_datetime: this.attr(),
      opening_reason: this.attr(),
      status_log: this.attr(),
    };
  }

  get isOpen() {
    return this.status.value === EbePrevisionEtp.STATUS_A_COMPLETER || this.status.value === EbePrevisionEtp.STATUS_A_VALIDER;
  }

  static getIsDSNValid(inputStatus) {
    return inputStatus === DSNImport.STATUS_VALIDE;
  }

  static getDSNStatus(inputStatus) {
    const status = EbePrevisionEtpMois.DSN_STATUSES.find((s) => s.value === inputStatus);
    return status || this.DSN_STATUSES[1];
  }

  static apiConfig = {
    actions: {
      ...EbePrevisionEtp.apiConfig.actions,
      async getByPrevisionEtpAnneeId(id, params = {}) {
        const response = await this.load({ ebe_prevision_etp_annee: id, ...params });
        return response.entities[this.model.entity] || [];
      },
      async saveOpenMonths(months) {
        const openedMonths = months.filter((month) => month.isOpen);
        return await EbePrevisionEtpMois.api().save(openedMonths);
      },
      async open(params = {}) {
        return await this.post(`${this.model.resourceUrl}open/`, params);
      },
      async close(params = {}) {
        return await this.post(`${this.model.resourceUrl}close/`, params);
      },
    },
  };
}
