import BaseModel from "@/models/BaseModel.js";
import Ebe from "@/models/Ebe.js";

export default class Cle extends BaseModel {
  static entity = "cle";
  static resourceUrl = "/cle/";

  static TITLE = Object.freeze({
    MADAME: "MADAME",
    MONSIEUR: "MONSIEUR",
    NON_COMMUNIQUE: "NON_COMMUNIQUE",
  });

  static TITLE_ITEMS = Object.freeze([
    { text: "Madame", value: Cle.TITLE.MADAME },
    { text: "Monsieur", value: Cle.TITLE.MONSIEUR },
    { text: "Non communiqué", value: Cle.TITLE.NON_COMMUNIQUE },
  ]);

  static fields() {
    return {
      id: this.uid(),
      nom: this.string(""),
      created_datetime: this.attr(),
      // included in the createCle
      utilisateur: this.attr(),
      // included in the extra_infos
      date_arrete_habilitation: this.attr(),
      epci: this.attr(),
      slug: this.attr(),
      villes: this.attr(),
      adresse: this.attr(),
      presidents: this.attr(),
      // included in the suivi_infos
      staffsuivicle_users: this.attr(),
    };
  }

  static PERMISSION = Object.freeze({
    MANAGE: "notrexp:cle_manage",
    UPDATE: "notrexp:cle_update",
  });

  getEbes() {
    return Ebe.query()
      .where((ebe) => ebe.cles.includes(this.id))
      .orderBy("nom")
      .get();
  }
}
