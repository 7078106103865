import BaseModel from "@/models/BaseModel";

export default class UserActivityLog extends BaseModel {
  static entity = "user_activity_log";
  static resourceUrl = "/notrexp/user_activity_log/";

  static apiDateKey = "created_datetime";

  static fields() {
    return {
      id: this.uid(),
      title: this.attr(),
      user_data: this.attr(),
      created_datetime: this.attr(),
      action_type: this.attr(),
      action_element: this.attr(),
    };
  }

  static normalize(model) {
    return {
      id: model.id,
      key: `log-${model.id}`,
      date: model.created_datetime,
      user: model.user_data ? `${model.user_data.first_name} ${model.user_data.last_name}` : "",
      description: model.title,
      category: "logs",
    };
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async loadAndNormalize(params) {
        const [rawResults, count] = await this.loadWithResults(params);
        const results = (rawResults || []).map(this.model.normalize);
        return { results, count };
      },
      sendLog(action_type, action_element, title, ebes = [], cles = [], { first_only = false, hidden = false } = {}) {
        return this.post(this.model.resourceUrl, { action_type, action_element, title, ebes, cles, first_only, hidden });
      },
    },
  };
}
