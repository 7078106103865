<template>
  <v-menu offset-y nudge-bottom="6">
    <template v-slot:activator="{ on, attrs }">
      <v-btn elevation="0" v-bind="attrs" v-on="on" rounded class="ps-0">
        <v-avatar size="36">
          <v-icon size="36" color="primary">mdi-account-circle-outline</v-icon>
        </v-avatar>
        <v-icon right dark>mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item link @click="$route.name !== 'profile' && $router.push({ name: 'profile' })">
        <v-list-item-action>
          <v-avatar size="36">
            <v-icon size="36" color="primary">mdi-account-circle-outline</v-icon>
          </v-avatar>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> {{ authenticatedUser.first_name }} {{ authenticatedUser.last_name }} </v-list-item-title>
          <v-list-item-subtitle>{{ authenticatedUser.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list>
      <v-list-item @click="$route.name !== 'profile' && $router.push({ name: 'profile' })">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Mon profil</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Se déconnecter</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import authenticationMixin from "@/helpers/authenticationMixin.js";

export default {
  mixins: [authenticationMixin],
  methods: {
    async logout() {
      await this.$store.dispatch("authentication/logout", {
        // this prevent having a ?next=... after logout in the url
        postAction: () => this.$router.push({ name: "login" }),
      });
    },
  },
};
</script>
