export default {
  state: {
    title: "ETCLD notreXP",
    isAPIUnreachable: false,
  },
  actions: {
    setTitle(context, params) {
      context.commit("SET_APP_TITLE", { title: params.title });

      let documentTitle = "ETCLD notreXP";
      if (params.title) {
        documentTitle = `${params.title} - ${documentTitle}`;
      }
      document.title = documentTitle;
    },
  },
  mutations: {
    SET_APP_TITLE(state, params) {
      state.title = params.title || "";
    },
    SET_IS_API_UNREACHABLE(state, value) {
      state.isAPIUnreachable = value ?? false;
    },
  },
  namespaced: true,
};
