import BaseModel from "@/models/BaseModel.js";
import Individu from "@/models/notrexp/Individu.js";

export default class ProspectInfo extends BaseModel {
  static entity = "prospect_info";
  static resourceUrl = "/suivi_ppde/prospect_info/";

  static fields() {
    return {
      id: this.uid(),
      created_datetime: this.attr(),
      individu: this.hasOne(Individu, "prospect_info_id"),
      current_status: this.attr(),
      date_rencontre: this.attr(),
      participation_reunion_info_collective: this.attr(),
      date_reunion_info_collective: this.attr(),
      motivations: this.attr(""),
      moyen_connaissance: this.attr(),
      moyen_connaissance_autre: this.attr(),
      human_volontaire_order: this.attr(),
      prospectinfoeligibilite: this.attr(),
      prospectprofilprofessionnel: this.attr(),
    };
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async createWithIndividu(individu) {
        const response = await ProspectInfo.api().requestWrap({
          method: "post",
          url: `${ProspectInfo.resourceUrl}create_with_individu/`,
          payload: { individu, email: individu.email },
        });
        // TODO if response is wrong, find backup
        return response.entities?.prospect_info?.[0]?.id;
      },
      async modifyIdentite(id, data) {
        return await ProspectInfo.api().requestWrap({
          method: "post",
          url: `${ProspectInfo.resourceUrl}${id}/modify_identite/`,
          payload: data,
        });
      },
      excelExport(params) {
        let query = Object.entries(params)
          .map(([key, value]) => {
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
          })
          .join("&");

        window.location = `${process.env.VUE_APP_API_URL}${ProspectInfo.resourceUrl}excel_export/?${query}`;
      },
    },
  };
}
