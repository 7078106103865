import { Model } from "@vuex-orm/core";

export default class Departement extends Model {
  static entity = "departement";

  static fields() {
    return {
      id: this.uid(),
      region: this.attr(),
      nom: this.attr(),
      code_insee: this.attr(),
    };
  }

  static state() {
    return {
      allLoaded: false,
    };
  }

  static async getOrLoad(id) {
    let departement = this.query().where("id", id).first();
    if (departement) return departement;

    let response = null;
    try {
      response = await this.api().get(`/departements/${id}/`);
      return response.entities.departement[0];
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  static apiConfig = {
    actions: {
      loadAll() {
        let state = this.model.store().state.entities[this.model.entity];
        if (state.allLoaded) {
          return Promise.resolve();
        }
        return this.get("/departements/").then(() => {
          this.model.commit((state) => {
            state.allLoaded = true;
          });
        });
      },
    },
  };
}
