import { Model } from "@vuex-orm/core";
import Financeur from "../Financeur";

export default class PrevisionEcoActiviteFinancement extends Model {
  static entity = "prevision_eco_activite_financement";

  static fields() {
    return {
      id: this.uid(),
      prevision_eco_activite: this.attr(),
      montant: this.attr(),
      financeur: this.attr(Financeur.fields()),
    };
  }

  static apiConfig = {
    actions: {
      loadRelated(params) {
        return this.get("/pilotage/prevision_eco_activite_financement/", {
          params: params,
        }).then((response) => {
          const financements = response?.entities?.prevision_eco_activite_financement;
          if (!financements) {
            return Promise.resolve(response);
          }

          const promises = [];
          financements.reduce((alreadyLoaded, financement) => {
            const key = financement.financeur.id;
            if (!alreadyLoaded[key]) {
              promises.push(Financeur.loadRelated(financement.financeur));
              alreadyLoaded[key] = true;
            }
            return alreadyLoaded;
          }, {});

          return Promise.all(promises);
        });
      },
      load(params) {
        return this.get("/pilotage/prevision_eco_activite_financement/", {
          params: params,
        });
      },
      save(instance) {
        if (instance.id.startsWith("$")) {
          return this.post("/pilotage/prevision_eco_activite_financement/", instance);
        } else {
          return this.put(`/pilotage/prevision_eco_activite_financement/${instance.id}/`, instance);
        }
      },
      destroy(instance) {
        return this.delete(`/pilotage/prevision_eco_activite_financement/${instance.id}/`, {
          delete: instance.id,
        });
      },
    },
  };
}
