import BaseModel from "@/models/BaseModel.js";

export default class AccompagnementsSuivis extends BaseModel {
  static entity = "accompagnements_suivis";
  static resourceUrl = "/suivi_ppde/accompagnements_suivis/";

  static fields() {
    return {
      id: this.uid(),
      titre: this.attr(),
    };
  }
}
