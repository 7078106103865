import BaseModel from "@/models/BaseModel";

export default class ContactProfileListeDiffusion extends BaseModel {
  static entity = "contact_profile_liste_diffusion";
  static resourceUrl = "/annuaire/liste_diffusion/";

  static fields() {
    return {
      id: this.uid(),
      titre: this.attr(),
    };
  }
}
