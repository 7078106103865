import BaseModel from "@/models/BaseModel.js";

export default class PilotageClePrevisionAnnee extends BaseModel {
  static entity = "pilotage_cle_prevision_annee";
  static resourceUrl = "/pilotage_cle/pilotage_cle_prevision_annee/";

  static fields() {
    return {
      id: this.uid(),
      annee: this.attr(),
      pilotage_cle: this.attr(),
      personnes_concernees: this.attr(),
      nouveaux_volontaires: this.attr(),
      personnes_sorties_hors_ebe: this.attr(),
      personnes_autres_parcours: this.attr(),
    };
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async getByPilotageCleId(pilotageCleId) {
        const response = await this.load({ pilotage_cle: pilotageCleId });
        return response.entities[PilotageClePrevisionAnnee.entity];
      },
    },
  };
}
