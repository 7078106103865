import BaseModel from "@/models/BaseModel";

export default class ConstantData extends BaseModel {
  static entity = "constant_data";
  static resourceUrl = "/notrexp/constant_data/";
  static primaryKey = "identifier";

  static fields() {
    return {
      identifier: this.attr(),
      value: this.attr(),
    };
  }

  static apiConfig = {
    actions: {
      getByIdentifier(identifier) {
        return this.get(`${this.model.resourceUrl}?identifier=${identifier}`);
      },
      ...BaseModel.apiConfig.actions,
    },
  };
}
