import BaseModel from "@/models/BaseModel.js";

export default class CleRepriseVolontaires extends BaseModel {
  static entity = "cle_reprise_volontaires";
  static resourceUrl = "/suivi_ppde/cle_reprise_volontaires/";

  static fields() {
    return {
      id: this.uid(),
      cle: this.attr(),
      date_debut_collecte: this.attr(),
      cdd_moins_six_mois_hors_ebe: this.attr(),
      cdd_plus_six_mois_hors_ebe: this.attr(),
      cdi_hors_ebe: this.attr(),
      formation_ou_autre: this.attr(),
      creation_activite: this.attr(),
      sortie_liste_plus_volontaire: this.attr(),
      sortie_liste_plus_eligible: this.attr(),
    };
  }
  static getNumericFields() {
    return [
      "cdd_moins_six_mois_hors_ebe",
      "cdd_plus_six_mois_hors_ebe",
      "cdi_hors_ebe",
      "formation_ou_autre",
      "creation_activite",
      "sortie_liste_plus_volontaire",
      "sortie_liste_plus_eligible",
    ];
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async findByCleId(cleId) {
        const response = await this.load({ cle: cleId });
        return response.entities[CleRepriseVolontaires.entity]?.[0];
      },
      async saveFetch(hd) {
        const response = await this.save(new CleRepriseVolontaires({ ...hd }));
        return response.entities[CleRepriseVolontaires.entity]?.[0];
      },
    },
  };
}
