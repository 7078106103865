<template>
  <v-menu v-model="isMenuOpen" offset-y nudge-bottom="6" :close-on-content-click="false" class="menu white">
    <template #activator="{ on, attrs, value }">
      <v-btn elevation="0" v-bind="attrs" v-on="on" rounded small fab>
        <v-icon :class="{ 'text--disabled': !hasUnreadNotifications }">{{ value ? "mdi-bell" : "mdi-bell-outline" }}</v-icon>
        <div v-if="hasUnreadNotifications" class="notification-badge secondary">{{ unreadNotifications.length }}</div>
      </v-btn>
    </template>
    <v-list class="notification-list py-0 max-h-75-screen">
      <div>
        <v-list-item v-if="!hasUnreadNotifications">
          <v-list-item-content>
            <v-list-item-title class="text--disabled text-center text-small">Aucune nouvelle notification</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-expansion-panels accordion focusable v-model="openPanel">
          <NotificationMenuPanel
            v-for="notification in unreadNotifications"
            :key="notification.id"
            :notification="notification"
            @link-click="closeMenu"
            is-showing-actions
          />
          <v-expansion-panel v-if="hasReadNotifications" class="read-notifications-panel">
            <v-expansion-panel-header hide-actions class="text-button py-0 text-center">
              <div v-if="openPanel" class="d-flex align-center justify-center">
                Cacher les anciennes notifications
                <v-icon>mdi-chevron-up</v-icon>
              </div>
              <div v-else class="d-flex align-center justify-center">
                Voir les anciennes notifications
                <v-icon>mdi-chevron-down</v-icon>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels accordion>
                <NotificationMenuPanel v-for="notification in readNotifications" :key="notification.id" :notification="notification" />
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-list>
  </v-menu>
</template>

<script setup>
import { computed, ref } from "vue";

import NotificationMenuPanel from "@/components/utils/NotificationMenuPanel.vue";

import Notification from "@/models/Notification";

const isMenuOpen = ref(false);
const openPanel = ref(null);

const readNotifications = computed(() => Notification.getAPIChannel(Notification.STATUSES.CLOSED).all());
const unreadNotifications = computed(() => Notification.getAPIChannel(Notification.STATUSES.SENDED).all());
const hasReadNotifications = computed(() => readNotifications.value.length > 0);
const hasUnreadNotifications = computed(() => unreadNotifications.value.length > 0);

const closeMenu = () => {
  isMenuOpen.value = false;
};
</script>

<style lang="scss" scoped>
.notification-list {
  width: 640px;
}

.notification-item {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  > .content {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > .actions {
    white-space: nowrap;
  }
}

.v-menu__content {
  background: white;
}

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  translate: 50% -50%;
  border: 2px solid #fafafa !important;
  border-radius: 1rem 1rem 1rem 0.25rem;
  font-size: x-small;
  padding: 0 0.25rem;
  color: white;
  min-width: 1.75rem;
}

.read-notifications-panel::v-deep {
  > .v-expansion-panel-header {
    min-height: 2.5rem;
  }
  > .v-expansion-panel-content > .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
</style>
