import Vue from "vue";
import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";
import database from "@/models";

import app from "@/stores/App.js";
import authentication from "@/stores/Authentication.js";
import modal from "@/stores/Modal.js";
import storeNotification from "@/stores/StoreNotification.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [VuexORM.install(database)],
  state: {},
  mutations: {},
  actions: {
    async init(context) {
      await context.dispatch("authentication/init");
    },
  },
  modules: {
    app,
    authentication,
    modal,
    storeNotification,
  },
  strict: process.env.NODE_ENV !== "production",
});

export default store;
export const useStore = () => store;
