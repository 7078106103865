import { Model } from "@vuex-orm/core";

export default class Epci extends Model {
  static entity = "epci";

  static fields() {
    return {
      id: this.attr(),
      nom: this.attr(),
      nature: this.attr(),
      code_siren: this.attr(),
    };
  }

  static async getOrLoad(id, name) {
    if (!id && !name) return null;

    let epci = this.query().where("id", id).first();
    if (epci) return epci;

    let url = "";
    if (id) url = `/epcis/${id}/`;
    if (name) url = `/epcis/?nom=${name}`;

    let response = null;
    try {
      response = await this.api().get(url);
      return response.entities.epci[0];
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  static apiConfig = {
    actions: {
      search(value, offset) {
        return this.get("epcis/", {
          params: {
            search: value,
            limit: 50,
            offset: offset,
          },
        });
      },
    },
  };
}
