import BaseModel from "@/models/BaseModel.js";

export default class MoyenConnaissance extends BaseModel {
  static entity = "moyen_connaissance";
  static resourceUrl = "/suivi_ppde/moyen_connaissance/";

  static fields() {
    return {
      id: this.uid(),
      titre: this.attr(),
    };
  }
}
