import BaseModel from "@/models/BaseModel.js";

export default class EbePrevEffectif extends BaseModel {
  static entity = "ebe_prev_effectif";
  static resourceUrl = "/pilotage/ebe_prev_effectif/";

  static fields() {
    return {
      id: this.uid(),
      rdv_pilotage: this.attr(null),
      cle: this.attr(null),
      annee: this.string(),
      nbre_ppde: this.attr(null),
      nbre_etp_contractuel_ppde: this.attr(null),
      nbre_moyen_etp_contractuel_ppde: this.attr(null),
      nbre_moyen_etp_paye_ppde: this.attr(null),
      nbre_moyen_etp_travaille_ppde: this.attr(null),
      nbre_nppde: this.attr(null),
      nbre_etp_contractuel_nppde: this.attr(null),
      nbre_moyen_etp_contractuel_nppde: this.attr(null),
      nbre_moyen_etp_paye_nppde: this.attr(null),
      nbre_moyen_etp_travaille_nppde: this.attr(null),
      nbre_moyen_etp_travaille_ppde_locked: this.attr(),
      nbre_moyen_etp_travaille_nppde_locked: this.attr(),
      previous: this.attr(),
    };
  }

  static FORM_FIELDS = [
    "nbre_ppde",
    "nbre_etp_contractuel_ppde",
    "nbre_moyen_etp_contractuel_ppde",
    "nbre_moyen_etp_paye_ppde",
    "nbre_moyen_etp_travaille_ppde",
    "nbre_nppde",
    "nbre_etp_contractuel_nppde",
    "nbre_moyen_etp_contractuel_nppde",
    "nbre_moyen_etp_paye_nppde",
    "nbre_moyen_etp_travaille_nppde",
  ];

  validate() {
    return EbePrevEffectif.FORM_FIELDS.every((field) => this.validateField(field) === true);
  }

  validateField(field) {
    let v = this[field];

    if (isNaN(v) || v < 0) {
      return "Doit être un nombre supérieur ou égal à 0 ou vide.";
    }

    if (!v) {
      return true;
    }

    if (field == "nbre_ppde" && !Number.isInteger(v)) {
      return "Doit être un nombre entier.";
    }

    if (field == "nbre_nppde" && !Number.isInteger(v)) {
      return "Doit être un nombre entier.";
    }

    if (field == "nbre_etp_contractuel_ppde" && v > this.nbre_ppde) {
      return "Doit être inférieur ou égal au nombre de salarié·es au 31/12.";
    }

    if (field == "nbre_etp_contractuel_nppde" && v > this.nbre_nppde) {
      return "Doit être inférieur ou égal au nombre de salarié·es au 31/12.";
    }

    if (field == "nbre_moyen_etp_contractuel_ppde" && v > this.nbre_ppde) {
      return "Doit être inférieur ou égal au nombre de salarié·es au 31/12.";
    }

    if (field == "nbre_moyen_etp_contractuel_nppde" && v > this.nbre_nppde) {
      return "Doit être inférieur ou égal au nombre de salarié·es au 31/12.";
    }

    if (field == "nbre_moyen_etp_paye_ppde" && v > this.nbre_moyen_etp_contractuel_ppde) {
      return "Doit être inférieur ou égal au nombre moyen d'ETP contractuels.";
    }

    if (field == "nbre_moyen_etp_paye_nppde" && v > this.nbre_moyen_etp_contractuel_nppde) {
      return "Doit être inférieur ou égal au nombre moyen d'ETP contractuels.";
    }

    if (field === "nbre_moyen_etp_travaille_ppde") {
      if (v > this.nbre_ppde) {
        return "Doit être inférieur ou égal au nombre de salarié·es au 31/12.";
      }
      if (v > this.nbre_moyen_etp_contractuel_ppde) {
        return "Doit être inférieur ou égal au nombre moyen d'ETP contractuels.";
      }
    }

    if (field === "nbre_moyen_etp_travaille_nppde") {
      if (v > this.nbre_nppde) {
        return "Doit être inférieur ou égal au nombre de salarié·es au 31/12.";
      }
      if (v > this.nbre_moyen_etp_contractuel_nppde) {
        return "Doit être inférieur ou égal au nombre moyen d'ETP contractuels.";
      }
    }

    return true;
  }

  addPrevision(prevision) {
    this.nbre_ppde += prevision.nbre_ppde;
    this.nbre_etp_contractuel_ppde += prevision.nbre_etp_contractuel_ppde;
    this.nbre_moyen_etp_contractuel_ppde += prevision.nbre_moyen_etp_contractuel_ppde;
    this.nbre_moyen_etp_paye_ppde += prevision.nbre_moyen_etp_paye_ppde;
    this.nbre_moyen_etp_travaille_ppde += prevision.nbre_moyen_etp_travaille_ppde;
    this.nbre_nppde += prevision.nbre_nppde;
    this.nbre_etp_contractuel_nppde += prevision.nbre_etp_contractuel_nppde;
    this.nbre_moyen_etp_contractuel_nppde += prevision.nbre_moyen_etp_contractuel_nppde;
    this.nbre_moyen_etp_paye_nppde += prevision.nbre_moyen_etp_paye_nppde;
    this.nbre_moyen_etp_travaille_nppde += prevision.nbre_moyen_etp_travaille_nppde;
    if (prevision.previous) {
      this.previous = this.previous ? new EbePrevEffectif(this.previous).addPrevision(prevision.previous) : prevision.previous;
    }

    return this;
  }
}
