import BaseModel from "@/models/BaseModel.js";

export default class DashboardPilotageEbe extends BaseModel {
  static entity = "dashboard_pilotage_ebe";
  static resourceUrl = "/metabase/dashboard_pilotage_ebe/";

  static fields() {
    return {
      id: this.uid(),
      pilotage: this.attr(),
    };
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async retrieveDashboardPilotages(ebeId) {
        const response = await this.get(`${this.model.resourceUrl}retrieve_pilotages/`, { params: { ebe: ebeId } });
        return response.response.data.length ? response.response.data : null;
        // using the previous line instead of this one because the API can return multiple time the same dashboard id
        // then vuexorm fails and only keep a single entity has it's the same id
        // return response.entities[this.model.entity];
      },
      async retrieveLink(dashboardId, ebeId) {
        const response = await this.get(`${this.model.resourceUrl}${dashboardId}/retrieve_link/`, { params: { ebe: ebeId } });
        return response.entities[this.model.entity];
      },
    },
  };
}
