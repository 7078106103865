import BaseModel from "@/models/BaseModel.js";

export default class PilotageCleStrategieExhaustivite extends BaseModel {
  static entity = "pilotage_cle_strategie_exhaustivite";
  static resourceUrl = "/pilotage_cle/strategie_exhaustivite/";

  static fields() {
    return {
      id: this.uid(),
      cle: this.attr(),
      date_debut: this.attr(),
      date_fin: this.attr(),
      personnes_concernees_pde: this.attr(),
      personnes_pde: this.attr(),
      personnes_sorties_ebe: this.attr(),
      personnes_sorties_hors_ebe: this.attr(),
      methode_calcul: this.attr(),
    };
  }
}
