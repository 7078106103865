import BaseModel from "../BaseModel";

export default class CTEVersement extends BaseModel {
  static entity = "cte_versement";
  static resourceUrl = "/fec/cte_versement/";

  static fields() {
    return {
      id: this.uid(),
      ebe: this.attr(),
      montant: this.attr(),
    };
  }
}
