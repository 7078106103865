import BaseModel from "@/models/BaseModel";
import { downloadFile } from "@/helpers/ApiRequest";
import { ALERT_ERROR, ALERT_WARNING, addAlertDetails } from "@/helpers/alerts";

export default class EbePilotage extends BaseModel {
  static entity = "ebe_pilotage";
  static resourceUrl = "/pilotage/ebe_pilotage/";

  static STATUS_IN_PROGRESS = "IN_PROGRESS";
  static STATUS_VALIDATED_EBE = "VALIDATED_EBE";
  static STATUS_VALIDATED_ETCLD = "VALIDATED_ETCLD";

  static STATUSES = [
    { text: "À compléter par l'EBE", value: EbePilotage.STATUS_IN_PROGRESS, color: "grey", icon: "mdi-pen" },
    { text: "Validé par l'EBE", value: EbePilotage.STATUS_VALIDATED_EBE, color: "orange", icon: "mdi-check" },
    { text: "Validé par ETCLD", value: EbePilotage.STATUS_VALIDATED_ETCLD, color: "green", icon: "mdi-check-all" },
  ];

  static getStatus(statusName) {
    const status = EbePilotage.STATUSES.find((s) => s.value === statusName);
    return status || { text: "Statut inconnu", color: "red" };
  }
  getStatus() {
    return EbePilotage.getStatus(EbePilotage.getValidationStatus(this));
  }

  static STEPS_IDS = Object.freeze({
    PREVISION_EFFECTIF: "PREVISION_EFFECTIF",
    PREVISION_EFFECTIF_SUMMARY: "PREVISION_EFFECTIF_SUMMARY", // Multi-CLE

    PREVISION_ECO_ACTIVITE: "PREVISION_ECO_ACTIVITE",
    PREVISION_ECO_ACTIVITE_SUMMARY: "PREVISION_ECO_ACTIVITE_SUMMARY", // Multi-CLE

    PREVISION_ECONOMIQUE: "PREVISION_ECONOMIQUE",
    PLAN_INVESTISSEMENT: "PLAN_INVESTISSEMENT",
    PREVISION_PLAN: "PREVISION_PLAN",
    PLAN_FINANCEMENT: "PLAN_FINANCEMENT",
    QUESTION_QUALITATIVE: "QUESTION_QUALITATIVE",
    VALIDATION: "VALIDATION",
  });

  static STEPS = Object.freeze({
    [EbePilotage.STEPS_IDS.PREVISION_EFFECTIF]: "Prévision d'effectif",
    [EbePilotage.STEPS_IDS.PREVISION_EFFECTIF_SUMMARY]: "Synthèse prévision d'effectif",
    [EbePilotage.STEPS_IDS.PREVISION_ECO_ACTIVITE]: "Prévision du modèle économique par activité",
    [EbePilotage.STEPS_IDS.PREVISION_ECO_ACTIVITE_SUMMARY]: "Synthèse prévision économique d'activité",
    [EbePilotage.STEPS_IDS.PREVISION_ECONOMIQUE]: "Compte de résultat prévisionnel",
    [EbePilotage.STEPS_IDS.PLAN_INVESTISSEMENT]: "Plan d'investissement",
    [EbePilotage.STEPS_IDS.PREVISION_PLAN]: "Plan de trésorerie",
    [EbePilotage.STEPS_IDS.PLAN_FINANCEMENT]: "Plan de financement",
    [EbePilotage.STEPS_IDS.QUESTION_QUALITATIVE]: "Questions qualitatives",
    [EbePilotage.STEPS_IDS.VALIDATION]: "Validation",
  });

  static fields() {
    return {
      id: this.uid(),
      ebe: this.attr(),
      nom: this.attr(),
      date_pilotage: this.attr(),
      steps_to_fill: this.attr(),
      taux_cde_departement: this.attr(),
      valider_ebe: this.attr(),
      valider_etcld: this.attr(),
    };
  }

  static PERMISSION = Object.freeze({
    READ: "pilotage:pilotage_read",
    UPDATE: "pilotage:pilotage_update",
    UPDATE_STATUS: "pilotage:pilotage_update_status",
    VALIDATE: "pilotage:pilotage_validate",
  });

  static state() {
    return {
      currentEbePilotage: null,
    };
  }

  getDatePilotage() {
    return new Date(new Date(this.date_pilotage).toDateString());
  }

  isBeforeMay2024() {
    return this.getDatePilotage() < new Date(2024, 4, 1);
  }

  isBeforeSeptember2024() {
    // Before september 2024 fields sorties_hors_ebe and sorties_autres_parcours was combined
    return this.getDatePilotage() < new Date(2024, 8, 1);
  }

  getNombreMoyenEtpDetails() {
    return this.isBeforeMay2024()
      ? { title: "Nombre moyen d'ETP contractuels", key: "nbr_moyen_etp_contractuel" }
      : { title: "Nombre moyen d'ETP travaillé", key: "nbr_moyen_etp_travaille" };
  }

  static getValidationStatus(ebePilotage) {
    if (ebePilotage.valider_ebe === true && ebePilotage.valider_etcld === true) {
      return this.STATUS_VALIDATED_ETCLD;
    }
    if (ebePilotage.valider_ebe === true && ebePilotage.valider_etcld === false) {
      return this.STATUS_VALIDATED_EBE;
    }
    if (ebePilotage.valider_ebe === false && ebePilotage.valider_etcld === false) {
      return this.STATUS_IN_PROGRESS;
    }
  }

  static computeEbePilotageStatus(ebePilotage, status) {
    if (status === EbePilotage.STATUS_VALIDATED_ETCLD) {
      ebePilotage.valider_etcld = true;
      ebePilotage.valider_ebe = true;
    } else if (status === EbePilotage.STATUS_VALIDATED_EBE) {
      ebePilotage.valider_etcld = false;
      ebePilotage.valider_ebe = true;
    } else if (status === EbePilotage.STATUS_IN_PROGRESS) {
      ebePilotage.valider_etcld = false;
      ebePilotage.valider_ebe = false;
    }

    return ebePilotage;
  }

  getNextYears(nYears) {
    let currentYear = new Date(this.date_pilotage).getFullYear();
    let years = [];
    for (let i = 0; i < nYears; i++) {
      years.push(currentYear + i + "-01-01");
    }
    return years;
  }

  getNextMonths(nMonths) {
    let now = new Date(this.date_pilotage);
    let months = [];
    for (let i = 0; i < nMonths; i++) {
      let date = new Date(now);
      date.setMonth(date.getMonth() + i);
      months.push(`${date.getFullYear()}/${("0" + (date.getMonth() + 1)).slice(-2)}/01`);
    }
    return months;
  }

  static getDatePilotageActive(date) {
    date.setUTCHours(0, 0, 0, 0);

    if (date >= new Date(Date.UTC(date.getFullYear(), 0, 1)) && date <= new Date(Date.UTC(date.getFullYear(), 0, 31))) {
      return new Date(Date.UTC(date.getFullYear() - 1, 9, 1));
    }
    if (date >= new Date(Date.UTC(date.getFullYear(), 8, 1)) && date <= new Date(Date.UTC(date.getFullYear(), 11, 31))) {
      return new Date(Date.UTC(date.getFullYear(), 9, 1));
    }
    if (date >= new Date(Date.UTC(date.getFullYear(), 1, 1)) && date <= new Date(Date.UTC(date.getFullYear(), 7, 31))) {
      return new Date(Date.UTC(date.getFullYear(), 2, 1));
    }

    return null;
  }

  export(step, cleId) {
    return EbePilotage.api().export(this.id, step, cleId);
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async _getErrors(pilotageId, stepName = null, dryRun = true) {
        try {
          const data = dryRun ? { dry_run: dryRun } : {};
          if (stepName) {
            data.step = stepName;
          }
          await this.post(`${this.model.resourceUrl}${pilotageId}/validate_ebe_pilotage/`, data, { handlingErrors: false });
        } catch (error) {
          const errors = error.response?.data?.validation_errors || [];
          return addAlertDetails(errors, ALERT_ERROR);
        }
        return [];
      },
      async _getWarnings(pilotageId, stepName = null) {
        const results = await this.get(`${this.model.resourceUrl}${pilotageId}/alerts/?dryRun=true${stepName ? `&step=${stepName}` : ""}`);
        const warnings = results?.response?.data || [];
        return addAlertDetails(warnings, ALERT_WARNING);
      },
      async getAlerts(pilotageId, stepName = null) {
        const errors = await EbePilotage.api()._getErrors(pilotageId, stepName, true);
        const warnings = await EbePilotage.api()._getWarnings(pilotageId, stepName);
        return [...errors, ...warnings];
      },
      /*Alias function for better naming outside, it calls the validate with no dry run and return the errors if any*/
      async validatePilotage(pilotageId) {
        return await EbePilotage.api()._getErrors(pilotageId, null, false);
      },
      async loadCurrent(id) {
        const response = await (id ? this.loadById(id) : this.load({ ordering: "-date_pilotage" }));
        const result = response.entities.ebe_pilotage?.[0] ?? null;
        this.model.commit((state) => {
          state.currentEbePilotage = result;
        });

        return result;
      },
      loadAvailableDates() {
        return this.get(`${this.model.resourceUrl}available_dates/`);
      },
      bulkAddStatus(pilotages, status) {
        const pilotagesData = pilotages.map((pilotage) => {
          const { valider_ebe, valider_etcld } = EbePilotage.computeEbePilotageStatus(pilotage, status);
          return new EbePilotage({ ...pilotage.originalEbePilotage, valider_ebe, valider_etcld });
        });
        return this.put(`${EbePilotage.resourceUrl}`, pilotagesData);
      },
      async export(pilotageId, step, cleId) {
        const params = new URLSearchParams();
        step && params.set("step", step);
        cleId && params.set("cle", cleId);

        const url = `${process.env.VUE_APP_API_URL}${EbePilotage.resourceUrl}${pilotageId}/export/?${params.toString()}`;
        await downloadFile(url);
      },
    },
  };
}
