import SimpleContainer from "@/views/SimpleContainer.vue";

const MetabaseCleDashboardSuiviVolontaire = () => import("@/views/metabase/MetabaseCleDashboardSuiviVolontaire.vue");
const MetabaseEbeDashboardSuivi = () => import("@/views/metabase/MetabaseEbeDashboardSuivi.vue");

const MetabaseDashboardPilotageEbe = () => import("@/views/metabase/MetabaseDashboardPilotageEbe.vue");

const routes = [
  {
    path: "/dashboard",
    component: SimpleContainer,
    meta: {
      title: "Tableaux de bord",
      icon: "mdi-chart-areaspline",
      displayInMenu: true,
      disabled: true,
      permissions: ["metabase:dashboard_suivi_volontaire", "metabase:dashboard_suivi_ebe", "metabase:dashboard_pilotage_ebe"],
    },
    children: [
      {
        path: "/dashboard/cle/suivi-volontaires",
        name: "dashboard-suivi-volontaires",
        component: MetabaseCleDashboardSuiviVolontaire,
        meta: {
          title: "Suivi volontaires",
          displayInMenu: true,
          permissions: ["metabase:dashboard_suivi_volontaire"],
        },
      },
      {
        path: "/dashboard/ebe/suivi",
        component: MetabaseEbeDashboardSuivi,
        meta: {
          title: "Direction EBE",
          displayInMenu: true,
          permissions: ["metabase:dashboard_suivi_ebe"],
        },
      },
      {
        path: "/dashboard/ebe/pilotage",
        name: "dashboard-ebe-pilotage",
        component: MetabaseDashboardPilotageEbe,
        meta: {
          title: "Saisie pilotage EBE",
          displayInMenu: true,
          permissions: ["metabase:dashboard_pilotage_ebe"],
        },
      },
    ],
  },
];

export default [...routes];
