import BaseModel from "@/models/BaseModel.js";

export default class DSNImportSalarie extends BaseModel {
  static entity = "dsn_import_salarie";
  static resourceUrl = "/financement/dsn_import_salarie/";

  static NULL = "None";
  static DEMANDE = "DEMANDE";
  static REFUS = "REFUS";
  static CONFIRME = "CONFIRME";
  static PPDE = "PPDE";
  static NPPDE = "NPPDE";
  static NHABILITE = "NHABILITE";

  static REMUNERATION_UNITE_HEURE = "10";
  static REMUNERATION_UNITE_FORFAIT_JOUR = "20";

  static REMUNERATION_UNITES = [
    {
      text: "Heure",
      value: DSNImportSalarie.REMUNERATION_UNITE_HEURE,
    },
    {
      text: "Forfait jour",
      value: DSNImportSalarie.REMUNERATION_UNITE_FORFAIT_JOUR,
    },
  ];

  static REMUNERATION_UNITE_SPECIFIC_JOURNEE = "12";
  static REMUNERATION_UNITE_SPECIFIC_FORFAIT_HEURE = "21";
  static REMUNERATION_UNITE_SPECIFIC_PIGE = "31";
  static REMUNERATION_UNITE_SPECIFIC_VACATION = "32";
  static REMUNERATION_UNITE_SPECIFIC_TACHE = "33";
  static REMUNERATION_UNITE_SPECIFIC_SMIC = "34";
  static REMUNERATION_UNITE_SPECIFIC_PART = "35";
  static REMUNERATION_UNITE_SPECIFIC_NON_CONCERNE = "99";

  static REMUNERATION_UNITES_SPECIFIC = [
    {
      text: "Journée",
      value: DSNImportSalarie.REMUNERATION_UNITE_SPECIFIC_JOURNEE,
    },
    {
      text: "Forfait heure",
      value: DSNImportSalarie.REMUNERATION_UNITE_SPECIFIC_FORFAIT_HEURE,
    },
    {
      text: "À la pige",
      value: DSNImportSalarie.REMUNERATION_UNITE_SPECIFIC_PIGE,
    },
    {
      text: "À la vacation",
      value: DSNImportSalarie.REMUNERATION_UNITE_SPECIFIC_VACATION,
    },
    {
      text: "À la tâche",
      value: DSNImportSalarie.REMUNERATION_UNITE_SPECIFIC_TACHE,
    },
    {
      text: "Au SMIC",
      value: DSNImportSalarie.REMUNERATION_UNITE_SPECIFIC_SMIC,
    },
    {
      text: "À la part",
      value: DSNImportSalarie.REMUNERATION_UNITE_SPECIFIC_PART,
    },
    {
      text: "Salarié·e non concerné·e",
      value: DSNImportSalarie.REMUNERATION_UNITE_SPECIFIC_NON_CONCERNE,
    },
  ];

  static fields() {
    return {
      id: this.attr(),
      dsn_import: this.attr(),
      nouveau: this.attr(),
      cle: this.attr(),
      ebe: this.attr(),
      validation_status: this.attr(),
      ppde_status: this.attr(),
      merge_individu: this.attr(),
      ebe_etablissement: this.attr(),
      date_entree_estimee: this.attr(),
      individu_id: this.attr(),
      nom: this.attr(),
      nom_usage: this.attr(),
      prenoms: this.attr(),
      date_naissance: this.attr(),
      statut_boeth: this.attr(),
      etp_contractuels: this.attr(),
      etp_contractuels_original: this.attr(),
      etp_payes: this.attr(),
      etp_travailles: this.attr(),
      etp_travailles_original: this.attr(),
      remunerations: this.attr([]),
    };
  }

  static PERMISSION = Object.freeze({
    READ: "financement:dsnimportsalarie_read",
  });

  getStatus() {
    if (this.validation_status == DSNImportSalarie.DEMANDE) {
      return "En attente de réponse du CLE";
    }
    if (this.validation_status == DSNImportSalarie.CONFIRME) {
      return "PPDE - Confirmé par le CLE";
    }
    if (this.validation_status == DSNImportSalarie.REFUS) {
      return "Refus du CLE";
    }
    if (this.ppde_status == DSNImportSalarie.PPDE) {
      return "PPDE";
    }
    if (this.ppde_status == DSNImportSalarie.NPPDE) {
      return "Non-PPDE";
    }
    if (this.ppde_status == DSNImportSalarie.NHABILITE) {
      return "Hors habilitation";
    }

    return "Aucun statut";
  }

  getStatusColor() {
    if (this.validation_status == DSNImportSalarie.DEMANDE) {
      return "grey";
    }
    if (this.validation_status == DSNImportSalarie.CONFIRME) {
      return "green";
    }
    if (this.validation_status == DSNImportSalarie.REFUS) {
      return "red";
    }
    if (this.ppde_status == DSNImportSalarie.NPPDE) {
      return "yellow";
    }
    if (this.ppde_status == DSNImportSalarie.NHABILITE) {
      return "orange";
    }

    return "";
  }

  static getUniteText(unite) {
    const allUnites = [...DSNImportSalarie.REMUNERATION_UNITES, ...DSNImportSalarie.REMUNERATION_UNITES_SPECIFIC];
    return allUnites.find(({ value }) => unite === value)?.text || unite;
  }
}
