import { Model } from "@vuex-orm/core";
import Financeur from "../Financeur";

export default class EbePlanInvestissementFinancement extends Model {
  static entity = "ebe_plan_investissement_financement";

  static fields() {
    return {
      id: this.uid(),
      plan_investissement: this.attr(),
      acquis: this.attr(),
      montant: this.attr(),
      financeur: this.attr(Financeur.fields()),
    };
  }

  static apiConfig = {
    actions: {
      loadRelated(params) {
        return this.get("/pilotage/ebe_plan_investissement_financement/", {
          params: params,
        }).then((response) => {
          var financements = response?.entities?.ebe_plan_investissement_financement;
          let promises = [];
          for (let financement of financements) {
            promises.push(Financeur.loadRelated(financement.financeur));
          }
          return Promise.all(promises);
        });
      },
      load(params) {
        return this.get("/pilotage/ebe_plan_investissement_financement/", {
          params: params,
        });
      },
      save(instance) {
        if (instance.id.startsWith("$")) {
          return this.post("/pilotage/ebe_plan_investissement_financement/", instance);
        } else {
          return this.put(`/pilotage/ebe_plan_investissement_financement/${instance.id}/`, instance);
        }
      },
      destroy(instance) {
        return this.delete(`/pilotage/ebe_plan_investissement_financement/${instance.id}/`, {
          delete: instance.id,
        });
      },
    },
  };
}
