export const PHONE_NUMBER_REGEX = /^[1-9]\d{8}$/;
export const PHONE_NUMBER_WITH_COUNTRY_CODE_REGEX = /^\+\d{1,3}[1-9]\d{8}$/;

export const isValidPhoneNumber = (phoneNumber) => {
  const regex = phoneNumber.startsWith("+") ? PHONE_NUMBER_WITH_COUNTRY_CODE_REGEX : PHONE_NUMBER_REGEX;
  return regex.test(phoneNumber);
};

export const splitPhoneNumber = (phoneNumberWithCountryCode) => {
  const phoneNumber = phoneNumberWithCountryCode.slice(-9);
  const countryCode = phoneNumberWithCountryCode.replace(phoneNumber, "");
  return [countryCode, phoneNumber];
};
