import BaseModel from "@/models/BaseModel";

export default class ProspectInfoNotification extends BaseModel {
  static entity = "prospect_info_notification";
  static resourceUrl = "/suivi_ppde/prospect_info_notification/";

  static NOTIFICATION_TYPE_SORTIE_EBE = "SORTIE_EBE";
  static NOTIFICATION_TYPE_STATUS_UPDATE = "STATUS_UPDATE";
  static NOTIFICATION_TYPE_STATUS_INVALID = "STATUS_INVALID";
  static NOTIFICATION_TYPES = [
    { text: "Sortie d'EBE", value: this.NOTIFICATION_TYPE_SORTIE_EBE },
    { text: "Changement de statut", value: this.NOTIFICATION_TYPE_STATUS_UPDATE },
    { text: "Statut invalide", value: this.NOTIFICATION_TYPE_STATUS_INVALID },
  ];

  static fields() {
    return {
      id: this.uid(),
      prospect_info: this.attr(),
      created_datetime: this.attr(),
      message: this.attr(),
      notification_type: this.attr(),
      read_datetime: this.attr(null),
    };
  }

  static getType(type) {
    const found_type = this.NOTIFICATION_TYPES.find((notificationType) => notificationType.value === type);
    if (!found_type) {
      return { text: "", value: "" };
    }
    return found_type;
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      markAsRead(id) {
        return this.post(`${this.model.resourceUrl}${id}/mark_as_read/`);
      },
    },
  };
}
