import BaseModel from "@/models/BaseModel.js";

export default class PilotageClePrevision extends BaseModel {
  static entity = "pilotage_cle_prevision";
  static resourceUrl = "/pilotage_cle/pilotage_cle_prevision/";

  static fields() {
    return {
      id: this.uid(),
      pilotage_cle: this.attr(),
      deld_nbr: this.attr(),
      deld_source: this.attr(),
      brsa_nbr_ft: this.attr(),
      brsa_source_ft: this.attr(),
      brsa_nbr_non_ft: this.attr(),
      brsa_source_non_ft: this.attr(),
      inscrits_nbr: this.attr(),
      inscrits_source: this.attr(),
      non_reperes_nbr: this.attr(),
      non_reperes_source: this.attr(),
      autres_nbr: this.attr(),
      autres_source: this.attr(),
      reponse_leviers_cle: this.attr(),
      reponse_maj_necessaire: this.attr(),
      demande_maj_strategie: this.attr(),
      use_maj_question: this.attr(),
    };
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async findByPilotageCleId(pilotageCleId) {
        if (!pilotageCleId) {
          return;
        }
        const response = await this.load({ pilotage_cle: pilotageCleId });
        return response.entities[PilotageClePrevision.entity][0];
      },
      async requestChangeStrategieExhaustivite(previsionId, reponse_maj_necessaire) {
        if (!previsionId) {
          return false;
        }

        try {
          await this.put(`${this.model.resourceUrl}${previsionId}/request_maj_strategie_exhaustivite/`, { reponse_maj_necessaire }, { save: false });
          return true;
        } catch (error) {
          return false;
        }
      },
    },
  };
}
