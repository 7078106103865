const Users = () => import("@/views/Users.vue");

const routes = [
  {
    path: "/gestion/utilisateurs",
    component: Users,
    name: "gestion-utilisateur-list",
    meta: {
      title: "Gestion des utilisateur·ice·s",
      icon: "mdi-account-group",
      displayInMenu: true,
      permissions: ["notrexp:user_read"],
    },
  },
  {
    path: "/gestion/utilisateurs/:id",
    component: Users,
    name: "gestion-utilisateur",
    meta: {
      permissions: ["notrexp:user_read"],
    },
  },
];

export default routes;
