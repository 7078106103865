import BaseModel from "@/models/BaseModel";

export default class Salarie extends BaseModel {
  static entity = "salarie";
  static resourceUrl = "/financement/salarie";

  static fields() {
    return {
      id: this.uid(),
      nom: this.attr(),
      nom_usage: this.attr(),
      prenoms: this.attr(),
      ebes: this.attr([]),
      code_postal: this.attr(),
      ppde_type: this.attr(),
      date_naissance: this.attr(),
      individu_id: this.attr(),
    };
  }
}
