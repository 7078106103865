import { Model } from "@vuex-orm/core";

export default class EbePrevisionPlan extends Model {
  static entity = "ebe_prevision_plan";

  static fields() {
    return {
      id: this.uid(),
      rdv_pilotage: this.attr(),
      solde: this.attr(),
      date_solde: this.attr(),
    };
  }

  static apiConfig = {
    actions: {
      load(params) {
        return this.get("/pilotage/ebe_prevision_plan/", {
          params: params,
        });
      },
      save(instance) {
        if (instance.id.startsWith("$")) {
          return this.post("/pilotage/ebe_prevision_plan/", instance);
        } else {
          return this.put(`/pilotage/ebe_prevision_plan/${instance.id}/`, instance);
        }
      },
    },
  };
}
