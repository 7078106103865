import BaseModel from "@/models/BaseModel.js";

export default class PilotageCleRealise extends BaseModel {
  static entity = "pilotage_cle_realise";
  static resourceUrl = "/pilotage_cle/pilotage_cle_realise/";

  static fields() {
    return {
      id: this.uid(),
      personnes_concernees: this.attr(),
      pilotage_cle: this.attr(),
      reponse_actions_exhaustivite: this.attr(),
      reponse_mobilisation_volontaire: this.attr(),
      reponse_strategie_information: this.attr(),
    };
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async findByPilotageCleId(pilotageCleId) {
        if (!pilotageCleId) {
          return;
        }
        const response = await this.load({ pilotage_cle: pilotageCleId });
        return response.entities[PilotageCleRealise.entity][0];
      },
    },
  };
}
