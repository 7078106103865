import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/stores";
import Home from "@/views/Home.vue";
const DeveloperGuidelines = () => import("@/views/DeveloperGuidelines.vue");

import etcldRoutes from "@/routes/etcld";
import cleRoutes from "@/routes/cle";
import ebeRoutes from "@/routes/ebe";

import authenticationRoutes from "@/routes/authentication";
import dashboardRoutes from "@/routes/dashboard";

import usersRoutes from "@/routes/users";
import annuaireRoutes from "@/routes/annuaire";
import bilanRoutes from "@/routes/bilan";

import removedRoutes from "@/routes/removed";

Vue.use(VueRouter);

// type RouteMeta = {
//   title?: string,
//   icon?: string,
//   displayInMenu?: boolean,
//   hideNavigations?: boolean, // (default: false)
//   requiresLogin?: boolean, // (default: true)
//   disabled?: boolean,
//   groups?: Array<string>
//   permissions?: Array<string>,
//   menuGroup?: string | Regex, // (default: undefined, using route.path as a default.
//   notificationTriggers?: Array<string>,
//                       // Useful if your group has different paths in children)
// }

// type Route = {
//   path: string,
//   name?: string,
//   component: VueComponent,
//   props?: boolean, // (default: false, vue-router: pass props to Route Components)
//   meta?: RouteMeta,
//   children?: Array<Route>
// }

const developerRoutes = [
  {
    path: "/guidelines",
    name: "dev-guidelines",
    component: DeveloperGuidelines,
    meta: {
      title: "Developer Guidelines",
      icon: "mdi-pencil-ruler",
      displayInMenu: true,
    },
  },
];

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "Accueil",
      icon: "mdi-home",
      displayInMenu: true,
    },
  },
  ...usersRoutes,
  ...etcldRoutes,
  ...cleRoutes,
  ...ebeRoutes,
  ...dashboardRoutes,
  ...annuaireRoutes,
  ...bilanRoutes,
  ...authenticationRoutes,
  ...removedRoutes,
  ...(process.env.NODE_ENV === "development" ? developerRoutes : []),
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    return next("/");
  }

  if (to.meta.requiresLogin === false) {
    return next();
  }

  if (!store.state.authentication.authenticated) {
    return next(`/login?next=${to.path}`);
  }

  if (to.meta.groups === undefined && to.meta.permissions === undefined) {
    return next();
  }

  if (to.meta.groups) {
    return to.meta.groups.some((group) => store.getters["authentication/isInGroup"](group)) ? next() : next("/");
  }

  if (to.meta.permissions) {
    return to.meta.permissions.some((permission) => store.getters["authentication/hasPermission"](permission)) ? next() : next("/");
  }

  return next("/");
});

router.afterEach((to) => {
  to.meta?.title && store.dispatch("app/setTitle", { title: to.meta.title });
});

export default router;
