<template>
  <v-expansion-panel :readonly="!notification.message">
    <v-expansion-panel-header disable-icon-rotate hide-actions class="pl-3 pr-4">
      <div class="d-flex gap-2 w-full">
        <v-btn icon :class="{ invisible: !notification.message }">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <div class="d-flex flex-column gap-1 align-start flex-grow-1 text-truncate">
          <div class="w-full text-truncate">{{ notification.title }}</div>
          <div class="text--secondary">{{ getDateTimeString(notification.created_datetime) }}</div>
        </div>
        <div v-if="isShowingActions" class="d-flex align-center">
          <v-btn v-if="notification.link" :to="notification.link" @click="emit('link-click')" text small>Lien direct</v-btn>
          <BaseTooltip color="info" left>
            Supprimer
            <template #activator>
              <v-btn @click.stop="readNotification(notification)" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </BaseTooltip>
        </div>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div v-html="notification.message" class="text-justify mt-4" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script setup>
import BaseTooltip from "@/components/utils/BaseTooltip.vue";

import { formatToDisplay } from "@/helpers/dates";

import Notification from "@/models/Notification";

defineProps({
  notification: Notification,
  isShowingActions: Boolean,
});

const emit = defineEmits(["link-click"]);

const getDateTimeString = (date) => formatToDisplay(date, "fullDate");

const readNotification = async (notification) => {
  try {
    await notification.read();
  } catch (error) {
    console.error(error);
  }
};
</script>

<style lang="scss" scoped>
.invisible {
  visibility: hidden;
}
</style>
