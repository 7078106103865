<template>
  <span class="app-title d-none">{{ title }}</span>
</template>
<script>
export default {
  props: {
    title: String,
  },
  watch: {
    title: {
      immediate: true,
      handler() {
        if (this.title) {
          this.$store.dispatch("app/setTitle", { title: this.title });
        }
      },
    },
  },
};
</script>
