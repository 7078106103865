<template>
  <div class="page">
    <keep-alive>
      <component :is="$route.meta?.listComponent" insideKeepAlive />
    </keep-alive>
    <router-view :id="id" />
  </div>
</template>

<script>
/**
 * This generic component allows us to have a List that keeps it's state
 * even when we open the detailed view and go back.
 *
 * To use it you need in the router to define a route:
 * {
 *   path: "/ressource",
 *   component: ListViewContainer,
 *   meta: {
 *     ...
 *     listComponent: MyList,
 *   },
 *   children: [
 *     {
 *       path: ":id",
 *       component: MyView,
 *     },
 *   ]
 */
export default {
  name: "ListViewContainer",
  computed: {
    id() {
      return this.$route.params.id?.toString();
    },
  },
};
</script>
