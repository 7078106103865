import store from "@/stores";

export default class Modal {
  static confirm(title, message, params) {
    return this.open({ title: title, message: message, type: "confirm", ...params });
  }

  static alert(title, message, type = "error") {
    return this.open({ title, message, type });
  }

  // Specific modal state for handling API errors
  static apiErrors(title, messages, type = "error") {
    return this.open({ title, messages, type });
  }

  static open(params) {
    return store.dispatch("modal/addModal", params);
  }
}
