import BaseModel from "@/models/BaseModel.js";
import Individu from "@/models/notrexp/Individu.js";

export default class IndividuContact extends BaseModel {
  static entity = "individu_contact";
  static resourceUrl = "/suivi_ppde/individu_contact/";

  static fields() {
    return {
      id: this.uid(),
      adresse: this.attr(),
      adresse_complementaire: this.attr(),
      code_postal: this.attr(),
      email: this.attr(),
      individu_id: this.attr(null),
      individu: this.belongsTo(Individu, "individu_id"),
      modified_date: this.attr(),
      tel_fixe: this.attr(),
      tel_portable: this.attr(),
      ville: this.attr(),
      commentaire: this.attr(),
    };
  }
  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async getForIndividu(individuId) {
        return await IndividuContact.api().requestWrap({
          url: `${IndividuContact.resourceUrl}?individu=${individuId}`,
        });
      },
    },
  };
}
