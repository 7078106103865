import { Model } from "@vuex-orm/core";

export default class EbeCategorieInvestissement extends Model {
  static entity = "ebe_categorie_investissement";

  static fields() {
    return {
      id: this.uid(),
      nom: this.attr(),
    };
  }

  static state() {
    return {
      loaded: false,
    };
  }

  static apiConfig = {
    actions: {
      loadAll() {
        this.model.commit((state) => {
          state.loaded = true;
        });
        return this.get("/pilotage/ebe_categorie_investissement/", {});
      },
    },
  };
}
