<template>
  <InputNumberOverride v-bind="$attrs" v-on="$listeners" :class="cssClasses" v-model="localValue" :rules="computedRules">
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"><slot :name="slot" v-bind="scope" /></template>
  </InputNumberOverride>
</template>

<script>
import InputNumberOverride from "@/components/utils/vuetify/InputNumberOverride";

export default {
  components: {
    InputNumberOverride,
  },
  props: {
    value: null,
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    allowNegative: {
      type: Boolean,
      default() {
        return false;
      },
    },
    align: {
      type: String,
      default: "right",
      validator(val) {
        return ["left", "center", "right"].includes(val);
      },
    },
  },
  data: () => ({}),
  computed: {
    isBorderless() {
      const isReadOnly = !!this.$attrs.readonly || this.$attrs.readonly === "";
      const isDisabled = !!this.$attrs.disabled || this.$attrs.disabled === "";

      return !this.$attrs.label && (isReadOnly || isDisabled);
    },
    cssClasses() {
      return {
        borderless: this.isBorderless,
        [`input-number-${this.align}`]: true,
      };
    },
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    computedRules() {
      let rules = [];
      for (let rule of this.rules) {
        rules.push(() => rule(this.localValue));
      }
      if (!this.allowNegative) {
        rules.push(() => {
          if (this.localValue < 0) return "La valeur ne peut pas être négative.";
          return true;
        });
      }
      return rules;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-number-left::v-deep input {
  text-align: left;
}
.input-number-center::v-deep input {
  text-align: center;
}
.input-number-right::v-deep input {
  text-align: right;
}
.v-input.borderless::v-deep fieldset {
  border: none;
}
.x-dense.v-input {
  font-size: 0.875rem;
}
.x-dense-padding::v-deep .v-input__slot:not(:has(label)) {
  padding: 0px 4px 0px 1px !important;
}
.x-dense::v-deep .v-input__slot:not(:has(label)) {
  padding: 3px 4px 0px 1px !important;
}
</style>
