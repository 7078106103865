import BaseModel from "@/models/BaseModel.js";

export default class StaffSuiviCle extends BaseModel {
  static entity = "staff_suivi_cle";
  static resourceUrl = "/suivi_territoire/staff_suivi_cle/";

  static fields() {
    return {
      id: this.attr(null),
      user: this.attr(),
      cle: this.attr(),
      in_wallet: this.boolean(false),
      subscribe_notification: this.boolean(false),
      created_datetime: this.attr(),
    };
  }
}
