import BaseModel from "@/models/BaseModel.js";

export default class StaffSuiviEbe extends BaseModel {
  static entity = "staff_suivi_ebe";
  static resourceUrl = "/suivi_territoire/staff_suivi_ebe/";

  static fields() {
    return {
      id: this.attr(null),
      user: this.attr(),
      ebe: this.attr(),
      subscribe_notification: this.boolean(false),
      created_datetime: this.attr(),
    };
  }
}
