import BaseModel from "@/models/BaseModel.js";

export default class PilotageClePrevisionSortieEbe extends BaseModel {
  static entity = "pilotage_cle_prevision_sortie_ebe";
  static resourceUrl = "/pilotage_cle/pilotage_cle_prevision_sortie_ebe/";

  static fields() {
    return {
      id: this.uid(),
      pilotage_cle: this.attr(),
      ebe: this.attr(),
      personnes_sorties_ebe: this.attr(),
      annee: this.attr(),
    };
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async getByPilotageCleId(pilotageCleId) {
        const response = await this.load({ pilotage_cle: pilotageCleId });
        return response.entities[PilotageClePrevisionSortieEbe.entity];
      },
    },
  };
}
