import BaseModel from "@/models/BaseModel";

export default class SuiviTerritoirePriseContactMoyen extends BaseModel {
  static entity = "suivi_territoire_prise_contact_moyen";
  static resourceUrl = "/suivi_territoire/prise_contact_moyen/";

  static fields() {
    return {
      id: this.uid(),
      titre: this.attr(),
      description: this.attr(),
    };
  }
}
