export const ALERT_INFO = "info";
export const ALERT_WARNING = "warning";
export const ALERT_ERROR = "error";

export const ALERTS = {
  [ALERT_INFO]: {
    color: "info",
    icon: "mdi-information-outline",
    alertType: ALERT_INFO,
  },
  [ALERT_WARNING]: {
    color: "secondary-high-contrast",
    icon: "mdi-alert-outline",
    alertType: ALERT_WARNING,
  },
  [ALERT_ERROR]: {
    color: "error",
    icon: "mdi-close-circle-outline",
    alertType: ALERT_ERROR,
  },
};

export const addAlertDetails = (alerts, alertType) => (alerts ? [...alerts.map((alert) => ({ ...alert, ...ALERTS[alertType] }))] : []);
