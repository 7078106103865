import { VTextField } from "vuetify/lib";

const readableNumber = (value, delimiter = " ") => value?.toString().replace(/\B(?<!,\d*)(?=(\d{3})+(?!\d))/g, delimiter);

export default {
  extends: VTextField,

  props: {
    type: {
      type: String,
      default: "float",
      validator(val) {
        return ["int", "float", "money"].includes(val);
      },
    },
    decimals: {
      type: Number,
      default: 2,
    },
    nullable: {
      type: Boolean,
      default: false,
    },
    delimiter: {
      type: String,
      default: " ",
    },
  },
  data() {
    return {
      manualEdited: false,
    };
  },
  computed: {
    decimalRegex() {
      let regex = `([+-]?(([0-9]*)?[,]?([0-9]){0,${this.decimals}}))`;
      return new RegExp(regex);
    },
    internalValue: {
      get() {
        return this.lazyValue;
      },
      set(value) {
        this.lazyValue = value;
        let parsedValue = this.displayToValue(this.lazyValue);
        if (!isNaN(parsedValue)) {
          this.$emit("input", parsedValue);
        }
      },
    },
  },
  methods: {
    onInput(e) {
      // Override the VTextField.onInput method
      const target = e.target;
      this.internalValue = this.cleanEditValue(target.value);

      target.value = this.internalValue;
      this.badInput = target.validity && target.validity.badInput;
      this.manualEdited = true;
    },
    valueToDisplay(value) {
      if (value == null || value == undefined) {
        return "";
      }

      if (typeof value == "string") {
        value = this.cleanEditValue(value);
        value = this.displayToValue(value);
      }

      if (!this.manualEdited && (this.type == "float" || this.type == "money")) {
        let decimals = Math.pow(10, this.decimals);
        value = (Math.round(value * decimals) / decimals).toFixed(this.decimals);
      }

      value = value.toString();
      value = this.cleanEditValue(value);

      if (!this.manualEdited) {
        value = readableNumber(value, this.delimiter);
      }

      return value;
    },
    displayToValue(value) {
      if (value == null || value == undefined || value == "") {
        return this.nullable ? null : 0;
      }

      value = value.replace(",", ".");

      if (this.type == "int") {
        return parseInt(value);
      }

      if (this.type == "float" || this.type == "money") {
        return parseFloat(value);
      }
      return value;
    },
    cleanEditValue(value) {
      if (typeof value !== "string") {
        return value;
      }
      value = value.replace(/\s/g, "");

      if (this.type == "int") {
        value = value.replace(/[^\d-]/g, "");
      }

      if (this.type == "float" || this.type == "money") {
        value = value.replace(".", ",");
        value = value.replace(/[^\d+\-,]/g, "");
        let match = value.match(this.decimalRegex);
        if (!match) {
          value = null;
        }
        value = match[1];
      }
      return value;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.lazyValue = this.valueToDisplay(value);
      },
    },
  },
};

export { readableNumber };
