import BaseModel from "@/models/BaseModel.js";

export default class TypeIndicateurPde extends BaseModel {
  static entity = "type_indicateur_pde";
  static resourceUrl = "/suivi_ppde/type_indicateur_pde/";

  static fields() {
    return {
      id: this.uid(),
      titre: this.attr(),
      description: this.attr(),
    };
  }
}
