<template>
  <div class="h-100 w-100">
    <DisplayMetabase v-if="metabaseDashboard && !metabaseError" :metabase-view="metabaseDashboard" @metabase-error="metabaseError = true" />
    <v-container v-else class="h-100 w-100 d-flex flex-column">
      <v-row justify="center" style="height: 50%">
        <v-col cols="12" sm="6" md="4">
          <div class="h-100 d-flex align-center">
            <img :src="require('@/assets/etcld_logo.svg')" style="width: 100%" />
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" style="height: 50%">
        <v-col cols="12" sm="6">
          <div class="h-100 d-flex align-center flex-column">Bonjour {{ authenticatedUser?.first_name }}, bienvenue sur NotreXP.</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DisplayMetabase from "@/components/metabase/DisplayMetabase.vue";
import authenticationMixin from "@/helpers/authenticationMixin.js";

export default {
  name: "Home",
  mixins: [authenticationMixin],
  components: {
    DisplayMetabase,
  },
  data() {
    return {
      metabaseError: false,
    };
  },
  computed: {
    metabaseDashboard() {
      if (this.hasPermission("metabase:dashboard_home_etcld")) {
        return "dashboard_home_etcld";
      }
      if (this.hasPermission("metabase:dashboard_home_cle")) {
        return "dashboard_home_cle";
      }
      if (this.hasPermission("metabase:dashboard_home_ebe")) {
        return "dashboard_home_ebe";
      }
      return "";
    },
  },
};
</script>
