import BaseModel from "@/models/BaseModel";
import PrevisionEcoActiviteFinancement from "@/models/pilotage/PrevisionEcoActiviteFinancement.js";

export default class PrevisionEcoActivite extends BaseModel {
  static entity = "prevision_eco_activite";
  static resourceUrl = "/pilotage/previsions_eco_activite/";

  static fields() {
    return {
      id: this.uid(),
      ebe_rdv_activite: this.attr(),
      nbr_moyen_etp_contractuel: this.attr(null),
      nbr_moyen_etp_travaille: this.attr(null),
      ca: this.attr(null),
      achat_consomme: this.attr(null),
      annee: this.string(),
      previous: this.attr(),
    };
  }

  static async getOrLoad(ebeRdvActivite, year) {
    let prevEco = this.query()
      .where((prevision) => {
        return prevision.annee == year && prevision.ebe_rdv_activite == ebeRdvActivite;
      })
      .first();

    if (prevEco) {
      return prevEco;
    }

    let response = await this.api().get(`pilotage/previsions_eco_activite/?ebe_rdv_activite=${ebeRdvActivite}&annee=${year}`);
    return response.entities.prevision_eco_activite?.[0] || {};
  }

  getSubventions() {
    let financements = PrevisionEcoActiviteFinancement.query().where("prevision_eco_activite", this.id).get();
    let subvention = 0;
    for (let financement of financements) {
      subvention += financement.montant;
    }
    return subvention;
  }

  getMargeBrut() {
    return (parseInt(this.ca) || 0) + this.getSubventions() - (parseInt(this.achat_consomme) || 0);
  }

  loadFinancements() {
    return PrevisionEcoActiviteFinancement.api().load({
      prevision_eco_activite: this.id,
    });
  }

  static apiConfig = {
    actions: {
      load(params, financements) {
        let requests = this.get(this.resourceUrl, {
          params: params,
        });

        if (!financements) {
          return requests;
        }

        return requests.then((response) => {
          let previsions = response?.entities?.prevision_eco_activite;
          if (!previsions) {
            return;
          }

          let requestFinancements = [];
          for (let prevision of previsions) {
            requestFinancements.push(prevision.loadFinancements());
          }
          return Promise.all(requestFinancements);
        });
      },
      ...BaseModel.apiConfig.actions,
    },
  };
}
