import BaseModel from "@/models/BaseModel";

export default class EbeEtablissement extends BaseModel {
  static entity = "ebe_etablissement";
  static resourceUrl = "/notrexp/ebe_etablissement/";

  static STATUS_ACTIF = "ACTIF";
  static STATUS_HORS_EXPERIMENTATION = "HORS_EXPERIMENTATION";
  static STATUS_FERME = "FERME";
  static STATUS_EN_ATTENTE_EBE = "EN_ATTENTE_EBE";
  static STATUS_EN_ATTENTE_ETCLD = "EN_ATTENTE_ETCLD";
  // Colours are reused and come from ProspectStatus.js
  static STATUSES = [
    {
      text: "Actif",
      value: EbeEtablissement.STATUS_ACTIF,
      color: "hsl(105, 76%, 87%, 1)",
      textColor: "black",
      icon: "mdi-check",
    },
    {
      text: "Hors expérimentation",
      value: EbeEtablissement.STATUS_HORS_EXPERIMENTATION,
      color: "hsl(19, 95%, 92%, 1)",
      textColor: "black",
      icon: "mdi-progress-helper",
    },
    {
      text: "Fermé",
      value: EbeEtablissement.STATUS_FERME,
      color: "grey lighten-2",
      textColor: "black",
      icon: "mdi-progress-close",
    },
    {
      text: "En attente de l'EBE",
      value: EbeEtablissement.STATUS_EN_ATTENTE_EBE,
      color: "hsl(43, 100%, 86%, 1)",
      textColor: "black",
      icon: "mdi-pencil",
    },
    {
      text: "En attente d'ETCLD",
      value: EbeEtablissement.STATUS_EN_ATTENTE_ETCLD,
      color: "hsl(43, 100%, 86%, 1)",
      textColor: "black",
      icon: "mdi-pencil",
    },
  ];

  static SELECTABLE_CLE_UNAVAILABLE = "CLE_UNAVAILABLE";
  static SELECTABLE_HORS_EXPERIMENTATION = "HORS_EXPERIMENTATION";
  static SELECTABLE_OTHER = "OTHER";
  static SELECT_EQUIVALENCE = {
    [EbeEtablissement.SELECTABLE_CLE_UNAVAILABLE]: EbeEtablissement.STATUS_EN_ATTENTE_ETCLD,
    [EbeEtablissement.SELECTABLE_HORS_EXPERIMENTATION]: EbeEtablissement.STATUS_HORS_EXPERIMENTATION,
    [EbeEtablissement.SELECTABLE_OTHER]: EbeEtablissement.STATUS_EN_ATTENTE_ETCLD,
  };

  static ASSIGNMENT_TYPE_AUTO = "AUTO";
  static ASSIGNMENT_TYPE_MANUEL_EBE = "MANUEL_EBE";
  static ASSIGNMENT_TYPE_MANUEL_ETCLD = "MANUEL_ETCLD";
  static ASSIGNMENT_TYPES = [
    {
      text: "Automatique",
      value: EbeEtablissement.ASSIGNMENT_TYPE_AUTO,
      color: "hsl(220, 96%, 90%, 1)",
      textColor: "black",
      icon: "mdi-memory",
    },
    {
      text: "Manuel par l'EBE",
      value: EbeEtablissement.ASSIGNMENT_TYPE_MANUEL_EBE,
      color: "hsl(105, 76%, 87%, 1)",
      textColor: "black",
      icon: "mdi-account-tag",
    },
    {
      text: "Manuel par ETCLD",
      value: EbeEtablissement.ASSIGNMENT_TYPE_MANUEL_ETCLD,
      color: "hsl(43, 100%, 86%, 1)",
      textColor: "black",
      icon: "mdi-account-tag",
    },
  ];

  static fields() {
    return {
      id: this.uid(),
      ebe: this.attr(),
      cle: this.attr(),
      siret: this.attr(),
      status_log: this.attr(),
      in_edit_mode: this.attr(),
      assignment_type: this.attr(),

      // included in update action,
      // avoids new EbeEtablissement() removing data
      status: this.attr(),
      message: this.attr(),
    };
  }

  getStatus() {
    return EbeEtablissement.getStatus(this);
  }

  static getStatus(etablissement) {
    const status = EbeEtablissement.STATUSES.find((s) => s.value === etablissement.status_log[0].value);
    return status ?? { text: "Statut inconnu", color: "red", icon: "mdi-alert" };
  }

  static getEquivalentStatus(selectOption) {
    return EbeEtablissement.SELECT_EQUIVALENCE[selectOption] ?? (selectOption ? EbeEtablissement.STATUS_ACTIF : null);
  }

  getAssignmentType() {
    const assignmentType = EbeEtablissement.ASSIGNMENT_TYPES.find((t) => t.value === this.assignment_type);
    return assignmentType ?? { text: "Type inconnu" };
  }

  static getSiretColorMap(ebe_id) {
    let lightnessMin = 40;
    let lightnessMax = 90;
    let siretMap = {};

    const etablissements = EbeEtablissement.query().where("ebe", ebe_id).get();
    let siretList = etablissements.map(({ siret }) => siret);

    if (siretList.length < 5) {
      lightnessMin = 90 - siretList.length * 10;
    }
    siretList.sort().reverse();

    for (let i = 0; i < siretList.length; i++) {
      let siret = siretList[i];
      let color = (i / (siretList.length - 1)) * (lightnessMax - lightnessMin) + lightnessMin;
      siretMap[siret] = `hsl(0, 0%, ${color}%)`;
    }

    return siretMap;
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      update(id, data) {
        return this.put(`${this.model.resourceUrl}${id}/`, data);
      },
      async loadByEbe(ebeId) {
        const response = await EbeEtablissement.api().load({ ebe: ebeId });
        const unsortedEtablissements = response?.entities[EbeEtablissement.entity] ?? [];
        return unsortedEtablissements.toSorted((a, b) => {
          return new Date(a.status_log.at(-1).datetime) - new Date(b.status_log.at(-1).datetime);
        });
      },
    },
  };
}
