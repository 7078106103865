import { Model } from "@vuex-orm/core";

export default class EbePrevisionPlanTresorerie extends Model {
  static entity = "ebe_prevision_plan_tresorerie";

  static fields() {
    return {
      id: this.uid(),
      prevision_plan: this.attr(),
      plan_tresorerie: this.attr(),
      montant: this.attr(),
      date_mois: this.attr(),
    };
  }

  static apiConfig = {
    actions: {
      load(params) {
        return this.get("/pilotage/ebe_prevision_plan_tresorerie/", {
          params: params,
        });
      },
      save(instance) {
        if (instance.id.startsWith("$")) {
          return this.post("/pilotage/ebe_prevision_plan_tresorerie/", instance, { delete: instance.id }).then((response) => {
            response.save();
          });
        } else {
          return this.put(`/pilotage/ebe_prevision_plan_tresorerie/${instance.id}/`, instance);
        }
      },
    },
  };
}
