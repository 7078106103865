import BaseModel from "@/models/BaseModel.js";

export default class TypesContrat extends BaseModel {
  static entity = "types_contrat";
  static resourceUrl = "/suivi_ppde/types_contrat/";

  static fields() {
    return {
      id: this.uid(),
      titre: this.attr(),
    };
  }
}
