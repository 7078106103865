import BaseModel from "@/models/BaseModel.js";

export default class PilotageCleProspectFreeze extends BaseModel {
  static entity = "pilotage_cle_prospect_freeze";
  static resourceUrl = "/pilotage_cle/pilotage_cle_prospect_freeze/";

  static fields() {
    return {
      id: this.uid(),
      pilotage_cle: this.attr(),
      nouveaux_volontaires: this.attr(),
      sorties_hors_ebe: this.attr(),
      sorties_ebe: this.attr(),
      sorties_hors_ebe_avant_pilotage: this.attr(),
      sorties_autres_parcours_avant_pilotage: this.attr(),
      sorties_autres_parcours: this.attr(),
      attente_moyenne: this.attr(),
      volontaires: this.attr(),
    };
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async summary(pilotageCleId) {
        const response = await this.get(`${PilotageCleProspectFreeze.resourceUrl}summary/?pilotage_cle=${pilotageCleId}`);
        return response.entities[PilotageCleProspectFreeze.entity][0];
      },
    },
  };
}
