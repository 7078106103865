import { Model } from "@vuex-orm/core";

export default class TypeFinanceur extends Model {
  static entity = "type_financeur";

  static TYPE_DONNEE_REGION = "REGION";
  static TYPE_DONNEE_DEPARTEMENT = "DEPARTEMENT";
  static TYPE_DONNEE_COMMUNE = "COMMUNE";
  static TYPE_DONNEE_EPCI = "EPCI";
  static TYPE_DONNEE_TEXTE = "TEXTE";
  static TYPE_DONNEE_NUL = "NUL";

  static fields() {
    return {
      id: this.uid(),
      nom: this.attr(),
      type_donnee: this.attr(),
      subvention: this.attr(false),
      plan_investissement: this.attr(true),
    };
  }

  static state() {
    return {
      allLoaded: false,
    };
  }

  static async getOrLoad(id) {
    let financeur = this.query().where("id", id).first();
    if (financeur) return financeur;

    let response = null;
    try {
      response = await this.api().get(`/notrexp/type_financeur/${id}/`);
      return response.entities.type_financeur[0];
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  static apiConfig = {
    actions: {
      loadAll() {
        let state = this.model.store().state.entities[this.model.entity];
        if (state.allLoaded) {
          return Promise.resolve();
        }
        return this.get("/notrexp/type_financeur/").then(() => {
          this.model.commit((state) => {
            state.allLoaded = true;
          });
        });
      },
    },
  };
}
