import { Model } from "@vuex-orm/core";

import EbeActiviteCategorie from "@/models/notrexp/EbeActiviteCategorie.js";

export default class EbeRdvActivite extends Model {
  static entity = "ebe_rdv_activite";

  static fields() {
    return {
      id: this.uid(),
      rdv_pilotage: this.attr(),
      activite: this.attr(),
      cle: this.attr(),
      intitule: this.attr(),
    };
  }

  static getActivities(pilotageId, cleId = null) {
    return EbeRdvActivite.query()
      .where((activity) => activity.rdv_pilotage === pilotageId && (cleId ? activity.cle === cleId : true))
      .orderBy((ebe_activite) => {
        let activite = EbeActiviteCategorie.find(ebe_activite.activite);
        if (activite.categorie) {
          activite = EbeActiviteCategorie.find(activite.categorie);
        }
        return activite.titre;
      })
      .get();
  }

  static apiConfig = {
    actions: {
      destroy(instance) {
        return this.delete(`/pilotage/ebe_rdv_activite/${instance.id}/`, {
          delete: instance.id,
        });
      },
      load(params) {
        return this.get("/pilotage/ebe_rdv_activite/", {
          params: params,
        });
      },
      save(instance) {
        if (instance.id.startsWith("$")) {
          return this.post("/pilotage/ebe_rdv_activite/", instance, instance, {
            delete: instance.id,
          }).then((response) => {
            response.save();
            return response;
          });
        } else {
          return this.put(`/pilotage/ebe_rdv_activite/${instance.id}/`, instance);
        }
      },
    },
  };
}
