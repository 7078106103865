import BaseModel from "@/models/BaseModel.js";

export default class FECDemandeCTE extends BaseModel {
  static entity = "fec_demande_cte";
  static resourceUrl = "/fec/fec_demande_cte/";

  static fields() {
    return {
      id: this.uid(),
      fec_import: this.attr(),
      demande: this.attr(),
      raison_demande: this.attr(),
    };
  }
}
