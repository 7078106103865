import BaseModel from "@/models/BaseModel";

export default class Notification extends BaseModel {
  static entity = "notification";
  static resourceUrl = "/notification/";

  static CHANNELS = Object.freeze({
    API_TECHNICAL: "API_TECHNICAL",
    API: "API",
    MAIL: "MAIL",
  });

  static STATUSES = Object.freeze({
    CREATED: "CREATED",
    SENDED: "SENDED",
    CLOSED: "CLOSED",
    ERROR: "ERROR",
  });

  static fields() {
    return {
      id: this.uid(),
      identifier: this.attr(),
      title: this.attr(),
      message: this.attr(),
      link: this.attr(),
      status: this.attr(),
      channel: this.attr(),
      created_user: this.attr(),
      created_datetime: this.attr(),
      close_datetime: this.attr(),
    };
  }

  static loadAllNotifications() {
    Notification.api().loadAPIChannel();
    Notification.api().loadAPITechnicalChannel();
  }

  static async reloadTechnical() {
    await Notification.deleteAll();
    return await Notification.api().load({ channel: this.CHANNELS.API_TECHNICAL, status: this.STATUSES.SENDED });
  }

  static getAPIChannel(statuses = [Notification.STATUSES.SENDED, Notification.STATUSES.CLOSED]) {
    return Notification.query().where("channel", Notification.CHANNELS.API).where("status", statuses);
  }

  static getAPITechnicalChannel() {
    return Notification.query().where("channel", Notification.CHANNELS.API_TECHNICAL).where("status", Notification.STATUSES.SENDED).all();
  }

  read() {
    return Notification.api().read(this.id);
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async loadAPIChannel() {
        return this.load({
          channel: "API",
          status__in: [Notification.STATUSES.SENDED, Notification.STATUSES.CLOSED].join(","),
        });
      },
      async loadAPITechnicalChannel() {
        return this.load({
          channel: "API_TECHNICAL",
          status: Notification.STATUSES.SENDED,
        });
      },
      async read(id) {
        return this.post(`${Notification.resourceUrl}${id}/read/`);
      },
    },
  };
}
