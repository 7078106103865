import BaseModel from "@/models/BaseModel.js";

export default class ProspectInfoEligibilite extends BaseModel {
  static entity = "prospect_info_eligibilite";
  static resourceUrl = "/suivi_ppde/prospect_info_eligibilite/";

  static SITUATION_PDE_PARTIELLE = "PARTIELLE";
  static SITUATION_PDE_REGULIERE = "REGULIERE";
  static SITUATION_PDE_TOTALE = "TOTALE";
  static SITUATION_PDE = [
    { text: "Partielle", value: this.SITUATION_PDE_PARTIELLE },
    { text: "Régulière", value: this.SITUATION_PDE_REGULIERE },
    { text: "Totale", value: this.SITUATION_PDE_TOTALE },
  ];

  static fields() {
    return {
      id: this.attr(),
      created_datetime: this.attr(),
      prospect_info: this.attr(),
      eligibilite: this.attr(),
      eligibilite_decision_date: this.attr(),
      eligibilite_justification: this.attr(null),
      eligibilite_situation_pde: this.attr(null),
      eligibilite_indicateur_pde: this.attr(null),
      eligibilite_indicateur_pde_autre: this.attr(null),
    };
  }

  static apiConfig = {
    actions: {
      validate(payload, config) {
        return this.post(`${this.model.resourceUrl}validate/`, payload, config);
      },
      ...BaseModel.apiConfig.actions,
    },
  };
}
