import BaseModel from "@/models/BaseModel.js";

export default class Group extends BaseModel {
  static entity = "group";
  static resourceUrl = "/notrexp/group/";

  static ETCLD = Object.freeze({
    GESTIONNAIRE_PAF: "etcld_gestionnaire_paf",
    GESTIONNAIRE_PAT: "etcld_gestionnaire_pat",
    CONTRIBUTEUR: "etcld_contributeur",
    LECTURE_SEULE: "etcld_lecture_seule",
    RESSOURCES: "etcld_ressources",
  });

  static ETCLD_GROUPS = Object.freeze([
    Group.ETCLD.GESTIONNAIRE_PAF,
    Group.ETCLD.GESTIONNAIRE_PAT,
    Group.ETCLD.CONTRIBUTEUR,
    Group.ETCLD.LECTURE_SEULE,
    Group.ETCLD.RESSOURCES,
  ]);

  static ETCLD_DEFAULT_GROUP = Group.ETCLD.CONTRIBUTEUR;

  static CLE = Object.freeze({
    GESTIONNAIRE: "cle_admin",
    CONTRIBUTEUR: "cle_contributeur",
    LECTURE_SEULE: "cle_lecture_seule",
    PARTENAIRE: "cle_partenaire",
    RESSOURCES: "cle_ressources",
  });

  static CLE_GROUPS = Object.freeze([
    Group.CLE.GESTIONNAIRE,
    Group.CLE.CONTRIBUTEUR,
    Group.CLE.LECTURE_SEULE,
    Group.CLE.PARTENAIRE,
    Group.CLE.RESSOURCES,
  ]);

  static CLE_DEFAULT_GROUP = Group.CLE.LECTURE_SEULE;

  static EBE = Object.freeze({
    GESTIONNAIRE: "ebe_admin",
    CONTRIBUTEUR: "ebe_contributeur",
    LECTURE_SEULE: "ebe_lecture_seule",
    RESSOURCES: "ebe_ressources",
  });

  static EBE_GROUPS = Object.freeze([Group.EBE.GESTIONNAIRE, Group.EBE.CONTRIBUTEUR, Group.EBE.LECTURE_SEULE, Group.EBE.RESSOURCES]);

  static EBE_DEFAULT_GROUP = Group.EBE.LECTURE_SEULE;

  static primaryKey = "identifier";

  static fields() {
    return {
      identifier: this.attr(""),
      name: this.attr(""),
      description: this.attr(""),
    };
  }

  static getETCLDGroups() {
    return Group.query()
      .where((group) => Group.ETCLD_GROUPS.includes(group.identifier))
      .get();
  }

  static getCLEGroups() {
    return Group.query()
      .where((group) => Group.CLE_GROUPS.includes(group.identifier))
      .get();
  }

  static getEBEGroups() {
    return Group.query()
      .where((group) => Group.EBE_GROUPS.includes(group.identifier))
      .get();
  }
}
