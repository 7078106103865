import BaseModel from "@/models/BaseModel.js";

export default class QuestionQualitative extends BaseModel {
  static entity = "question_qualitative";
  static resourceUrl = "/pilotage/question_qualitative/";

  static fields() {
    return {
      id: this.uid(),
      actif: this.attr(true),
      libelle: this.attr(""),
      question: this.attr(""),
      question_ebe: this.attr(false),
      question_cle: this.attr(false),
      optionnelle: this.attr(false),
      ordre_affichage: this.attr(0),
      etape: this.attr(),
    };
  }
}
