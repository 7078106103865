import BaseModel from "@/models/BaseModel.js";
export default class ProspectInfoSimilar extends BaseModel {
  static entity = "prospect_info_similar";
  static resourceUrl = "/suivi_ppde/prospect_info/";
  static fields() {
    return {
      id: this.uid(),
      individu: this.attr(),
      current_status: this.attr(),
    };
  }
  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async searchSimilar(data) {
        return await ProspectInfoSimilar.api().requestWrap({
          method: "post",
          url: `${ProspectInfoSimilar.resourceUrl}search_similar/`,
          payload: data,
        });
      },
    },
  };
}
