import BaseModel from "@/models/BaseModel";

export default class ContactProfileFonction extends BaseModel {
  static entity = "contact_profile_fonction";
  static resourceUrl = "/annuaire/fonction/";

  static USER_TYPE_ETCLD = "ETCLD";
  static USER_TYPE_CLE = "CLE";
  static USER_TYPE_EBE = "EBE";
  static USER_TYPE_EXTERNE = "EXT";

  static fields() {
    return {
      id: this.uid(),
      titre: this.attr(),
      user_type: this.attr(),
    };
  }

  static getETCLDFonctions() {
    return this.query()
      .where((fonction) => fonction.user_type.includes(this.USER_TYPE_ETCLD))
      .get();
  }

  static getCLEFonctions() {
    return this.query()
      .where((fonction) => fonction.user_type.includes(this.USER_TYPE_CLE))
      .get();
  }

  static getEBEFonctions() {
    return this.query()
      .where((fonction) => fonction.user_type.includes(this.USER_TYPE_EBE))
      .get();
  }

  static getExterneFonctions() {
    return this.query()
      .where((fonction) => fonction.user_type.includes(this.USER_TYPE_EXTERNE))
      .get();
  }

  static getContactFonctions() {
    return this.query()
      .where((fonction) => !fonction.user_type.includes(this.USER_TYPE_ETCLD))
      .get();
  }

  get fullTitle() {
    return `${this.user_type === this.USER_TYPE_EXTERNE ? "Externe" : this.user_type} - ${this.titre}`;
  }
}
