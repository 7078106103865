import useAuthentication from "@/helpers/composables/useAuthentication";

const { isETCLDUser, hasPermission, isInGroup, authenticatedUser, isAuthenticated } = useAuthentication();

export default {
  computed: {
    isETCLDUser() {
      return isETCLDUser.value;
    },
    authenticatedUser() {
      return authenticatedUser.value;
    },
    isAuthenticated() {
      return isAuthenticated.value;
    },
  },
  methods: {
    hasPermission(permission) {
      return hasPermission(permission);
    },
    isInGroup(group) {
      return isInGroup(group);
    },
  },
};
