import { Model } from "@vuex-orm/core";

export default class EbePrevisionEconomique extends Model {
  static entity = "ebe_prevision_economique";

  static fields() {
    return {
      id: this.uid(),
      rdv_pilotage: this.attr(),
      autre_prod_exploit_gest_courant: this.attr(null),
      ch_masse_ppde: this.attr(null),
      ch_masse_nppde: this.attr(null),
      ch_autre_charge_personnel: this.attr(null),
      ch_autre_achat_externe: this.attr(null),
      ch_autre_gestion_courante: this.attr(null),
      ch_dotation_amortissement_provision: this.attr(null),
      impot_taxe: this.attr(null),
      resultat_financier: this.attr(null),
      resultat_exceptionnel: this.attr(null),
      impot_societe_participation_salarie: this.attr(null),
      annee: this.attr(),
      financements: this.attr(() => []),
      dotation_amorcage: this.attr(),
      dotation_amorcage_original: this.attr(),
      dotation_amorcage_justification: this.attr(null),
      smic: this.attr(),
      smic_original: this.attr(),
      taux_cde_etat: this.attr(),
      taux_cde_etat_original: this.attr(),
      complement_cde_departement: this.attr(),
      complement_cde_departement_justification: this.attr(),
      previous: this.attr(),
    };
  }

  get computeTotalAutresChargesFonctionnement() {
    return this.ch_autre_achat_externe + this.impot_taxe + this.ch_autre_gestion_courante + this.ch_dotation_amortissement_provision;
  }

  static apiConfig = {
    actions: {
      load(params) {
        return this.get("/pilotage/ebe_prevision_economique/", {
          params: params,
        });
      },
      save(instance) {
        if (instance.id.startsWith("$")) {
          return this.post("/pilotage/ebe_prevision_economique/", instance);
        } else {
          return this.put(`/pilotage/ebe_prevision_economique/${instance.id}/`, instance);
        }
      },
    },
  };
}
