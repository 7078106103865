import BaseModel from "@/models/BaseModel";

export default class SuiviTerritoirePriseContactTypeEchange extends BaseModel {
  static entity = "suivi_territoire_prise_contact_type_echange";
  static resourceUrl = "/suivi_territoire/prise_contact_type_echange/";

  static fields() {
    return {
      id: this.uid(),
      titre: this.attr(),
    };
  }
}
