<template>
  <div>
    <v-snackbar fixed top center :color="notification.type" text outlined v-if="text" :value="true" class="text-center">
      <div class="text-center">{{ text }}</div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  computed: {
    notification() {
      return this.$store.state.storeNotification.notification;
    },
    text() {
      return this.notification?.text || "";
    },
  },
};
</script>
