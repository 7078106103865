import { computed } from "vue";
import { useStore } from "@/stores";

import Group from "@/models/notrexp/Group";

const { ETCLD_GROUPS, CLE_GROUPS, EBE_GROUPS } = Group;

const store = useStore();

export default function useAuthentication() {
  const hasPermission = (permission) => store.getters["authentication/hasPermission"](permission);
  const isInGroup = (group) => store.getters["authentication/isInGroup"](group);
  const isInAtLeastOneGroup = (groups) => store.getters["authentication/isInAtLeastOneGroup"](groups);

  const authenticatedUser = computed(() => store.state?.authentication?.user || {});
  const isAuthenticated = computed(() => store.state?.authentication?.authenticated || false);
  const isETCLDUser = computed(() => isInAtLeastOneGroup(ETCLD_GROUPS));
  const isCLEUser = computed(() => isInAtLeastOneGroup(CLE_GROUPS));
  const isEBEUser = computed(() => isInAtLeastOneGroup(EBE_GROUPS));

  return {
    hasPermission,
    isInGroup,
    isInAtLeastOneGroup,
    authenticatedUser,
    isAuthenticated,
    isETCLDUser,
    isCLEUser,
    isEBEUser,
  };
}
