const DEFAULT_TIMEOUT = 2000;

export default {
  state: {
    notification: null,
  },
  actions: {
    addNotification(context, { text, type, timeout }) {
      const notification = { text, type };
      context.commit("ADD_NOTIFICATION", notification);
      setTimeout(function () {
        context.dispatch("closeNotification");
      }, timeout || DEFAULT_TIMEOUT);
    },
    closeNotification(context) {
      context.commit("CLOSE_NOTIFICATION");
    },
  },
  mutations: {
    ADD_NOTIFICATION(state, { text, type }) {
      state.notification = { text, type };
    },
    CLOSE_NOTIFICATION(state) {
      state.notification = null;
    },
  },
  namespaced: true,
};
