import BaseModel from "@/models/BaseModel";
import { downloadFile } from "@/helpers/ApiRequest";

export default class SuiviTerritoireMeteoTerritoire extends BaseModel {
  static entity = "suivi_territoire_meteo_territoire";
  static resourceUrl = "/suivi_territoire/meteo_territoire/";

  static apiDateKey = "date_meteo";

  static fields() {
    return {
      id: this.uid(),
      cle: this.attr(),
      ebe: this.attr(),
      date_meteo: this.attr(),
      type_meteo: this.attr(),
      commentaires: this.attr(),
      preconisations: this.attr(),
      synthese: this.attr(),
      created_datetime: this.attr(),
      updated_datetime: this.attr(),
      created_user: this.attr(),
      updated_user: this.attr(),
    };
  }

  static PERMISSION = Object.freeze({
    READ: "suivi_territoire:meteoterritoire_read",
    MANAGE: "suivi_territoire:meteoterritoire_manage",
    EXPORT: "suivi_territoire:meteoterritoire_export",
  });

  static TYPES = [
    { text: "Suivi classique", value: "CLASSIQUE", color: "light-green lighten-1", textColor: "black" },
    { text: "Suivi adapté", value: "ADAPTE", color: "amber lighten-1", textColor: "black" },
    { text: "Suivi renforcé", value: "RENFORCE", color: "orange darken-1", textColor: "black" },
    { text: "Alerte", value: "ALERTE", color: "red lighten-1", textColor: "black" },
  ];

  get type() {
    return SuiviTerritoireMeteoTerritoire.TYPES.find((s) => s.value === this.type_meteo);
  }

  static normalize(model) {
    return {
      id: model.id,
      key: `meteo-${model.id}`,
      date: model.date_meteo,
      user: model.created_user ? `${model.created_user.first_name} ${model.created_user.last_name}` : "",
      description: model.synthese,
      meteoType: model.type,
      category: "meteos",
    };
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async loadAndNormalize(params) {
        const [rawResults, count] = await this.loadWithResults(params);

        const results = (rawResults || []).map(this.model.normalize);
        return { results, count };
      },
      async loadLatest() {
        const result = await this.get(`${this.model.resourceUrl}latest/`);
        return result.entities?.[this.model.entity];
      },
      async exportXlsx(params) {
        const urlParams = new URLSearchParams(params);
        const url = `${process.env.VUE_APP_API_URL}${SuiviTerritoireMeteoTerritoire.resourceUrl}export/?${urlParams.toString()}`;
        await downloadFile(url);
      },
    },
  };
}
