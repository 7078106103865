import { Model } from "@vuex-orm/core";

export default class CdeEtat extends Model {
  static entity = "cde_etat";

  static MIN_CDE_ETAT = 53;
  static MAX_CDE_ETAT = 102;

  static fields() {
    return {
      id: this.uid(),
      taux: this.attr(),
      taux_nppde: this.attr(),
      usage_date: this.attr(),
    };
  }

  getUsageDate() {
    return new Date(this.usage_date + "T00:00:00Z");
  }

  static state() {
    return {
      allLoaded: false,
    };
  }

  static computeYear(year, field, maxDate) {
    field = field || "taux";
    let allCde = CdeEtat.query();

    if (maxDate) {
      allCde = allCde.where((cde) => cde.getUsageDate() <= maxDate);
    }

    allCde = allCde.get().sort((a, b) => b.getUsageDate() - a.getUsageDate());

    let last_date = new Date(Date.UTC(new Date(year).getUTCFullYear(), 11, 31 + 1));
    let values = [];

    for (let cde of allCde) {
      let usage_date = cde.getUsageDate();
      if (usage_date > last_date) {
        continue;
      }

      let months = last_date.getUTCMonth() - usage_date.getUTCMonth();
      months += 12 * (last_date.getUTCFullYear() - usage_date.getUTCFullYear());
      months = Math.min(months, 12 - values.length);

      last_date = usage_date;
      for (let i = 0; i < months; i++) {
        values.unshift(cde[field]);
      }

      if (values.length >= 12) {
        break;
      }
    }

    let unknownValues = 12 - values.length;
    for (let i = 0; i < unknownValues; i++) {
      values.unshift(0.0);
    }

    return {
      months: values,
      avg: Math.round((values.reduce((a, b) => a + b, 0) / values.length) * 10000) / 10000,
    };
  }

  static apiConfig = {
    actions: {
      loadAll() {
        let state = this.model.store().state.entities[this.model.entity];
        if (state.allLoaded) {
          return Promise.resolve();
        }
        return this.get("/notrexp/cde_etat/").then(() => {
          this.model.commit((state) => {
            state.allLoaded = true;
          });
        });
      },
    },
  };
}
