import BaseModel from "@/models/BaseModel.js";

export default class ProspectInfoAdministrative extends BaseModel {
  static entity = "prospect_info_administrative";
  static resourceUrl = "/suivi_ppde/prospect_info_administrative/";

  static INSCRIPTION_CHOICES_YES = "YES";
  static INSCRIPTION_CHOICES_NO = "NO";
  static INSCRIPTION_CHOICES_UNKNOWN = "UNKNOWN";
  static INSCRIPTION_CHOICES = [
    { text: "Oui", value: this.INSCRIPTION_CHOICES_YES },
    { text: "Non", value: this.INSCRIPTION_CHOICES_NO },
    { text: "Ne sait pas", value: this.INSCRIPTION_CHOICES_UNKNOWN },
  ];
  static BOETH_CHOICES_YES = "YES";
  static BOETH_CHOICES_NO = "NO";
  static BOETH_CHOICES_IN_PROGRESS = "IN_PROGRESS";
  static BOETH_CHOICES = [
    { text: "Oui", value: this.BOETH_CHOICES_YES },
    { text: "Non", value: this.BOETH_CHOICES_NO },
    { text: "En cours de reconnaissance", value: this.BOETH_CHOICES_IN_PROGRESS },
  ];

  static fields() {
    return {
      id: this.attr(null),
      accompagnements_suivis: this.attr(),
      accompagnements_suivis_autre: this.attr(),
      droits_ouvert: this.attr(),
      france_travail_inscription: this.attr(),
      france_travail_date_inscription: this.attr(),
      france_travail_identifiant: this.attr(),
      boeth: this.attr(),
      domiciliation_eligible: this.attr(),
      domiciliation_justificatif: this.attr(),
      domiciliation_justificatif_original_name: this.attr(),
      emploi_recent: this.attr(),
      estimation_duree_pde: this.attr(),
      calcul_mensuel: this.attr(),
      periode_analyse: this.attr(),
      mois: this.attr(),
      moyenne_heures: this.attr(),
      debut_analyse_activite: this.attr(),
      type_contrats: this.attr(),
      type_contrats_actuel: this.attr(),
      commentaire_pde: this.attr(),
      commentaire_administration: this.attr(),
      prospect_id: this.attr(null),
    };
  }

  static apiConfig = {
    actions: {
      delete_justificatif_domicile(instance, config) {
        return this.requestWrap({
          method: "delete",
          url: `${this.model.resourceUrl}${instance.id}/delete_justificatif_domicile/`,
          config: { save: false, ...config },
        });
      },
      upload_justificatif_domicile(instance, payload, config) {
        return this.requestWrap({
          method: "post",
          url: `${this.model.resourceUrl}${instance.id}/upload_justificatif_domicile/`,
          payload: payload,
          config: { save: false, ...config },
        });
      },
      ...BaseModel.apiConfig.actions,
    },
  };
}
