import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import "dayjs/locale/fr";

dayjs.locale("fr");
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const formatDatetime = (date) => {
  if (typeof date == "string") {
    return new Date(date).toLocaleString("fr");
  }

  if (date instanceof Date) {
    return date.toLocaleString("fr");
  }

  return date;
};
// default:   from YYYY-mm-dd to dd/mm/YYYY
// birthdate: from ddmmYYYY to dd/mm/YYYY
export const formatToDisplay = (date, dateType = "date") => {
  if (!date) {
    return date;
  }

  let [year, month, day] = date.split("-");

  if (dateType == "birthdate") {
    day = date.slice(0, 2).replaceAll("99", "––");
    month = date.slice(2, 4).replaceAll("99", "––");
    year = date.slice(4, 8);
    return `${day}/${month}/${year}`;
  }

  if (dateType == "monthyear") {
    return month ? `${month}/${year}` : year;
  }

  if (dateType == "month") {
    return `${month}/${year}`;
  }

  if (dateType == "year") {
    return `${year}`;
  }

  if (dateType === "fullDate") {
    return dayjs(date).format("llll");
  }

  return `${day}/${month}/${year}`;
};

// default:   from dd/mm/YYYY to YYYY-mm-dd
// birthdate: from dd/mm/YYYY to ddmmYYYY
export const formatToModel = (date, dateType = "date") => {
  if (!date) {
    return date;
  }

  date = date.replaceAll("––", "99");

  let slices = date.split("/");

  if (dateType === "year" || (dateType == "monthyear" && slices.length == 1)) {
    slices = ["00", "00", slices[0]];
  } else if (["month", "monthyear"].includes(dateType) && slices.length == 2) {
    slices = ["00", slices[0], slices[1]];
  }

  const day = (slices[0] || "").padStart(2, "0");
  const month = (slices[1] || "").padStart(2, "0");
  const defaultYear = new Date().getFullYear().toString();
  // hacky way of prepending "19" or "20". If the current year is 2022 and the user type in < 22 then 20 otherwise 19
  const firstDigits = (slices[2] || defaultYear) > +defaultYear.slice(2, 4) ? "19" : "20";
  const year = (slices[2] || defaultYear).padStart(4, firstDigits);

  if (dateType == "birthdate") {
    return `${day}${month}${year}`;
  }

  if (dateType == "monthyear") {
    return month != "00" ? `${year}-${month}` : year;
  }

  if (dateType == "month") {
    return `${year}-${month}-01`;
  }

  if (dateType == "year") {
    return `${year}-01-01`;
  }

  return `${year}-${month}-${day}`;
};

// From Date to YYYY-mm-dd
export const dateToIsoString = (date) => {
  if (!date) {
    return date;
  }

  return date.toISOString().split("T")[0];
};

/** WARNING: this check only works with dd/mm/yyyy date. */
export const ruleDateNotInTheFuture = (v) => {
  if (!v) return true;
  const formatted = formatToModel(v);
  const date = new Date(formatted);
  // if invalid date (ie: 99 as a day), getTime is NaN and Nan is never equal to itself
  const isDateValid = date.getTime() === date.getTime();
  const isInTheFuture = isDateValid && date > new Date();
  return isInTheFuture ? "La date ne peut pas être dans le futur." : true;
};

// From ddmmYYYY string to years since
export const getAge = (date) => {
  // Assume user is born the 1st day or month of the year if we find 99
  const bDay = date.slice(0, 2).replace("99", "01");
  const bMonth = date.slice(2, 4).replace("99", "01");
  const bYear = date.slice(4);

  if (!bDay || !bMonth || !bYear) {
    return null;
  }

  const today = new Date();
  const [tYear, tMonth, tDay] = today.toISOString().split("T")[0].split("-");

  // Works for ddmmYYYY, 99mmYYYY, dd99YYYY & 9999YYYY
  if (tMonth < bMonth || (tMonth === bMonth && tDay < bDay)) {
    return tYear - bYear - 1;
  }
  return tYear - bYear;
};

// from 2x dd/MM/YYYY to year diff based on how
// many times 12 months have passed rounded down
export const getYearDifference = (startDate, endDate) => {
  const [start, end] = splitDates([startDate, endDate], "/");

  if (!isValidDate(start) || !isValidDate(end)) {
    return null;
  }

  return Math.floor((end.month - start.month + 12 * (end.year - start.year)) / 12);
};

// from 2x dd/MM/YYYY to month diff
export const getMonthDifference = (startDate, endDate) => {
  const [start, end] = splitDates([startDate, endDate], "/");

  if (!isValidDate(start) || !isValidDate(end)) {
    return null;
  }

  return end.month - start.month + 12 * (end.year - start.year);
};

// from 2x dd/MM/YYYY to true/false,
// use !isDateBefore() to effectively have isDateAfter
export const isDateBefore = (input, comparison) => {
  if (!input || !comparison) {
    return false;
  }
  const [sInput, sCompare] = splitDates([input, comparison], "/");
  if (!isValidDate(sInput) || !isValidDate(sCompare)) {
    return false;
  }
  const inputDate = new Date(sInput.year, sInput.month - 1, sInput.day);
  const compareDate = new Date(sCompare.year, sCompare.month - 1, sCompare.day);
  return inputDate <= compareDate;
};

// checks if input dd/MM/YYYY is in the specified period (2x dd/MM/YYYY)
export const isDateInPeriod = (input, periodStart, periodEnd) => {
  const inputDate = new Date(...input.split("/").reverse());
  const startDate = new Date(...periodStart.split("/").reverse());
  const endDate = new Date(...periodEnd.split("/").reverse());
  return inputDate > startDate && inputDate < endDate;
};

// with a range between 2 dd/MM/YYYY dates, find
// how many times the search dd/MM date appears
export const datesInRange = (startDate, endDate, searchDate) => {
  const [start, end] = splitDates([startDate, endDate], "/");
  if (!isValidDate(start) || !isValidDate(end)) {
    return [];
  }
  const sDate = new Date(start.year, start.month - 1, start.day);
  const eDate = new Date(end.year, end.month - 1, end.day);
  const [searchDay, searchMonth] = searchDate.split("/");
  let validSearchDates = [];

  for (let searchYear = start.year; searchYear <= end.year; searchYear++) {
    const tDate = new Date(searchYear, searchMonth - 1, searchDay);
    if (sDate <= tDate && tDate <= eDate) {
      validSearchDates.push(tDate);
    }
  }

  return validSearchDates;
};

// From 0 to 3
export const getQuarter = (date) => {
  return Math.floor((date.getMonth() + 3) / 3) - 1;
};

export const getQuarterTitle = (date) => {
  if (date === null || date === undefined) {
    return;
  }
  if (typeof date === "string") {
    date = new Date(date);
  }
  const quarter = getQuarter(date) + 1;
  const year = date.getFullYear();
  return `T${quarter} ${year}`;
};

// split dd/MM/YYYY (or other separator) into
// object with day, month, and year properties
export const splitDates = (dates = [], separator = "/") => {
  return dates.map((date) => {
    const [day, month, year] = date.split(separator);
    return {
      day,
      month,
      year,
    };
  });
};

// checks if every property is populated
const isValidDate = (date) => {
  return date.day && date.month && date.year;
};

export const dateControleRule = (
  v,
  controlDate,
  type = "date de disponibilité",
  message = "La date doit être après celle de la création du profil."
) => {
  if (!controlDate) {
    return false;
  }
  if (!v) {
    return `Veuillez renseigner une ${type}.`;
  }

  const vDate = formatToModel(v);
  const cDate = dateToIsoString(new Date(controlDate));

  return vDate >= cDate || message;
};

export const getFirstDayOfYear = (year) => {
  const firstDayOfYear = new Date(Date.UTC(year, 0, 1));
  const firstDayFormatted = formatToDisplay(dateToIsoString(firstDayOfYear));
  // const firstDayFormatted = new Intl.DateTimeFormat("fr-FR").format(firstDayOfYear);

  return firstDayFormatted;
};

export const getLastDayOfYear = (year) => {
  const lastDayOfYear = new Date(Date.UTC(year, 11, 31));
  const lastDayFormatted = formatToDisplay(dateToIsoString(lastDayOfYear));
  // const lastDayFormatted = new Intl.DateTimeFormat("fr-FR").format(lastDayOfYear);

  return lastDayFormatted;
};

export const getFirstAndLastDayOfYear = (year) => [getFirstDayOfYear(year), getLastDayOfYear(year)];

/**
 *? Can we please start use the dayjs package we've got to unify these dates
 *? and stop the pain and suffering us frontend devs have to deal with ?
 */

// precision units docs: https://day.js.org/docs/en/manipulate/start-of
export const isBefore = (input, comparison, params = {}) => {
  const { inputFormat, comparisonFormat, precision } = params;
  return dayjs(input, inputFormat).isBefore(dayjs(comparison, comparisonFormat), precision);
};

export const isBeforeOrSame = (input, comparison, params = {}) => {
  const { inputFormat, comparisonFormat, precision } = params;
  return dayjs(input, inputFormat).isSameOrBefore(dayjs(comparison, comparisonFormat), precision);
};

export const isAfter = (input, comparison, params = {}) => {
  const { inputFormat, comparisonFormat, precision } = params;
  return dayjs(input, inputFormat).isAfter(dayjs(comparison, comparisonFormat), precision);
};

export const isAfterOrSame = (input, comparison, params = {}) => {
  const { inputFormat, comparisonFormat, precision } = params;
  return dayjs(input, inputFormat).isSameOrAfter(dayjs(comparison, comparisonFormat), precision);
};

export const getYear = (input) => {
  return dayjs(input).year();
};

export const isValidStringDate = (input, inputFormat = "YYYY-MM-DD") => {
  return dayjs(input, inputFormat, true).isValid();
};

export const formatDate = (input, inputFormat = "YYYY-MM-DD") => {
  const date = dayjs(input, inputFormat, true);
  if (!date.isValid()) return null;
  return date.format("LL");
};

export const formatDateNumeric = (input, inputFormat = "YYYY-MM-DD") => {
  const date = dayjs(input, inputFormat, true);
  if (!date.isValid()) return null;
  return date.format("DD/MM/YYYY");
};
