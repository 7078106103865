// Put here the removed pages with a corresponding redirection.
// This helps avoid breaking links and migrate links in steps.
// These rules may be removed over time.

const routes = [
  {
    // Page removed, added the 2024-06-10
    path: "/dashboard/cle/pilotage",
    redirect: {
      name: "dashboard-suivi-volontaires",
    },
  },
];

export default [...routes];
