import BaseModel from "@/models/BaseModel";

export default class EbeEtpRealiseFinaliseMensuel extends BaseModel {
  static entity = "ebe_etp_realise_finalise_mensuel";
  static resourceUrl = "/financement/ebe_etp_realise_finalise_mensuel/";

  static TYPE_ETP_PAYE = "ETP_PAYE";
  static TYPE_ETP_TRAVAILLE = "ETP_TRAVAILLE";

  static fields() {
    return {
      id: this.uid(),
      ebe_id: this.attr(),
      cle_id: this.attr(),
      mois: this.attr(),
      etp_realise_type: this.attr(),
      effectif_ppde: this.attr(),
      etp_contractuels_ppde: this.attr(),
      etp_realise_ppde: this.attr(),
      etp_realise_prime_conges_payes_ppde: this.attr(),
      effectif_nppde: this.attr(),
      etp_contractuels_nppde: this.attr(),
      etp_realise_nppde: this.attr(),
      etp_realise_pris_en_charge_nppde: this.attr(),
      etp_realise_prime_conges_payes_nppde: this.attr(),
    };
  }

  static apiConfig = {
    actions: {
      async getEtpRealiseEbe(ebeId, months = []) {
        return await this.get(this.model.resourceUrl, {
          params: {
            ebe_id: ebeId,
            mois__in: months.join(","),
          },
        });
      },
      ...BaseModel.apiConfig.actions,
    },
  };
}
